import {
  SET_OPEN_ADDPRODUCTO,
  SET_OPEN_DELETEPRODUCTO,
  SET_OPEN_UPDATEPRODUCTO,
  SET_PRODUCTO,
  UPDATE_PRODUCTO,
  DELETE_PRODUCTO,
  SET_BUSQUEDA,
  ADD_PRODUCTO,
} from "../types";

const TablaProductoReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_OPEN_ADDPRODUCTO:
      return {
        ...state,
        estadoAddProducto: payload,
      };
    case SET_OPEN_UPDATEPRODUCTO:
      return {
        ...state,
        estadoUpdateProducto: payload.bool,
        editaProducto: payload.id == undefined ? {} : payload.id,
      };
    case SET_OPEN_DELETEPRODUCTO:
      return {
        ...state,
        estadoDeleteProducto: payload.bool,
        eliminaProducto: payload.id == undefined ? {} : payload.id,
      };
    case SET_PRODUCTO:
      payload.sort((a, b) => b.id - a.id);
      return {
        ...state,
        productos: payload,
        resultado: payload,
      };
    case ADD_PRODUCTO:
      payload.sort((a, b) => b.id - a.id);
      payload[0].tallas = payload[0].tallas.join();
      payload[0].colores = payload[0].colores.join();
      return {
        ...state,
        productos: payload,
        resultado: payload,
      };

    case UPDATE_PRODUCTO:
      return {
        ...state,
        resultado: state.productos.map((producto) =>
          producto.id == payload.id ? payload : producto
        ),
        productos: state.productos.map((producto) =>
          producto.id == payload.id ? payload : producto
        ),
      };
    case DELETE_PRODUCTO:
      return {
        ...state,
        productos: state.productos.filter(
          (producto) => producto.id !== payload
        ),
        resultado: state.resultado.filter(
          (producto) => producto.id !== payload
        ),
      };

    case SET_BUSQUEDA:
      return {
        ...state,
        resultado: payload,
      };
  }
};

export default TablaProductoReducer;
