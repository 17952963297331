import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useContext } from "react";
import TiendaContext from "../../context/tienda/TiendaContext";
import { toast } from "react-hot-toast";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function DatosCarrito({ empresa }) {
  const { verificaCuenta, verificaBeneficiario } = useContext(TiendaContext);

  return (
    <dl className="mt-6 space-y-4">
      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="text-sm font-medium text-gray-600">
          {" "}
          Debes ingresar tu rut para continuar
        </dt>
      </div>
      <dd className="text-sm font-medium text-gray-900">
        <Formik
          initialValues={{
            rut: "",
          }}
          onSubmit={async (values) => {
            const res = await verificaCuenta(values.rut);
            if (res) {
              window.location.href = `/${empresa.nombre}/datos-compra?rut=${values.rut}`;
            } else {
              const res = await verificaBeneficiario(values.rut);
              if (res) {
                toast("Necesitas activar tu cuenta para poder comprar.", {
                  icon: "⚠️",
                });
              } else {
                toast.error("Rut no encontrado.");
              }
            }
          }}
          validationSchema={Yup.object({
            rut: Yup.string().required(
              "Ingrese su rut sin puntos y con guión."
            )
            .test('len', 'Formato inválido.', val => val.length === 10 && val[8] === '-')
          })}
        >
          {({ errors, touched }) => (
            <Form className="space-y-6">
              <div className="mt-1">
                <Field
                  name="rut"
                  className={classNames(
                    errors.rut == undefined
                      ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                      : touched.rut
                      ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                      : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                    "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                  )}
                  placeholder="XXXXXXXX-X"
                />
                {touched.rut && errors.rut && (
                  <div className="text-red-500 text-xs">{errors.rut}</div>
                )}
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  className={classNames((localStorage.getItem("carrito") === "[]" || localStorage.getItem('carrito') === null) ? "bg-gray-400" : "bg-indigo-600 hover:bg-indigo-700", "w-full rounded-md border border-transparent py-3 px-4 text-base font-bold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50")}
                  disabled={(localStorage.getItem("carrito") === "[]" || localStorage.getItem('carrito') === null)}
                >
                  Continuar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </dd>
    </dl>
  );
}
