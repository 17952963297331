import axios from "axios";

export const addCatalogoApi = async (catalogo) => {

    return await axios.post('/catalogo/add', catalogo)
    
}

export const getCatalogoApi = async () => {return await axios.get('/catalogo/empresas')}

export const deleteCatalogoApi = async (id) => {
    return await axios.delete(`catalogo/delete/${id}`);
}

export const anexaCatalogoApi = async (form) => {
    return await axios.post('/api/v1/add-productos-catalogo', form)
}

export const getCatProdApi = async (id) => {
    return await axios.get(`/api/v1/get-cat-prod?id_catalogo=${id}`)
}

export const editCatalogoApi = async (form) => {
    return await axios.put('catalogo/edit/' + form.id, form)
}