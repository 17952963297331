import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import TablaPedidosContext from "../../../context/tablaPedidos/TablaPedidoContext";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { REACT_APP_API } from "../../../service/env";

export default function ResumenPedido() {
  const { openViewPedido, setOpenViewPedido, verPedido } =
    useContext(TablaPedidosContext);
  return (
    <Transition.Root show={openViewPedido} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenViewPedido}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-scroll">
          <div className="flex items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <Dialog.Panel className="relative transform overflow-scoll rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl sm:p-6 opacity-100 translate-y-0 sm:scale-100">
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                  <DocumentMagnifyingGlassIcon
                    className="h-6 w-6 text-yellow-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <div className="mt-2 bg-white shadow sm:rounded-lg">
                    <div className="border-t border-gray-200 grid grid-cols-3 text-xs sm:text-sm">
                      <div className="col-span-1">
                        <div className="bg-white px-4 py-5">
                          <dt className="text-gray-500">
                            Emisión Boleta
                          </dt>
                          <dd className="mt-1 text-gray-900">
                            {verPedido.fecha?.slice(0, 10)}
                          </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5">
                          <dt className="text-gray-500">
                            RUT
                          </dt>
                          <dd className="mt-1 text-gray-900">
                            {verPedido.rut_formateado}
                          </dd>
                        </div>
                        <div className="bg-white px-4 py-5">
                          <dt className="text-gray-500">
                            Nombre
                          </dt>
                          <dd className="mt-1 text-gray-900">
                            {verPedido.beneficiario_nombres} {" "}
                            {verPedido.beneficiario_apellido_paterno} {" "}
                            {verPedido.beneficiario_apellido_materno}
                          </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5">
                          <dt className="text-gray-500">
                            Total
                          </dt>
                          <dd className="mt-1 text-gray-900">
                            {verPedido.total?.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            })}
                          </dd>
                        </div>
                        <div className="bg-white px-4 py-5">
                          <dt className="text-gray-500">
                            N° Cuotas
                          </dt>
                          <dd className="mt-1 text-gray-900">
                            {JSON.stringify(verPedido.cuotas) == "{}" ? (
                              <span className="text-gray-900">Pago WEBPAY</span>
                            ) : (
                              verPedido.cuotas
                            )}
                          </dd>
                        </div>
                        <div className="bg-white px-4 py-5">
                          <dt className="text-gray-500">
                            Dirección Despacho
                          </dt>
                          <dd className="mt-1 text-gray-900 capitalize">
                            {verPedido.despacho}
                          </dd>
                        </div>
                      </div>
                      <div className="border-x border-gray-200 col-span-2">
                        <nav
                          className="max-h-screen overflow-scroll"
                          aria-label="Directory"
                        >
                          <div className="relative">
                            <div className="bg-gray-50 px-4 text-gray-500 py-5 text-center">
                              <h3>Productos</h3>
                            </div>
                            {verPedido.productos?.map((pedido, index) => (
                              <ul
                                role="list"
                                className="relative z-0 divide-y divide-gray-200"
                              >
                                <li className="bg-white">
                                  <div className="relative flex items-center px-4 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-gray-50">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="h-20 w-20 rounded-full"
                                        src={
                                          REACT_APP_API + "/" + pedido.imagen
                                        }
                                        alt="Imagen producto"
                                      />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <span
                                        className="absolute inset-0"
                                        aria-hidden="true"
                                      />
                                      <div className="mt-1 text-gray-900">
                                        {pedido.nombre} <br />
                                        <div className="grid grid-cols-2 mt-2 text-center text-gray-700 uppercase">
                                          <div>
                                            {pedido.talla !== null ? pedido.talla : "Sin talla"}
                                          </div>
                                          <div className="border-l border-gray-200">
                                            CANT. {pedido.cantidad}
                                          </div>
                                          <div className="border-t border-gray-200 col-span-2"></div>
                                          <div>
                                            {pedido.color}
                                          </div>
                                          <div className="uppercase border-l border-gray-200">
                                          {pedido.logo
                                            ? "Con logo"
                                            : "Sin logo"}
                                          </div>                                            
                                        </div>

                                        <p className="text-gray-700">
                                            {pedido.precio.toLocaleString(
                                              "es-CL",
                                              {
                                                style: "currency",
                                                currency: "CLP",
                                              }
                                            )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            ))}
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 grid">
                  <button
                    type="button"
                    className="inline-flex w-2/5 justify-center place-self-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => setOpenViewPedido(false, {})}
                  >
                    Cerrar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
