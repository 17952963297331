import { useReducer } from "react";
import TablaPedidosReducer from "./TablaPedidoReducer";
import TablaPedidosContext from "./TablaPedidoContext";
import {
  SET_OPEN_PEDIDO_DETAILS,
  SET_ENTREGAR_PEDIDO,
  SET_PEDIDO,
  VIEW_PEDIDOS,
  SET_OPEN_NUM_PEDIDO,
  SET_BUSQUEDA,
  SET_ACTUALIZAR_ESTADO
} from "../types";
import {
  getPedidos,
  putActualizarVenta,
  getPedidosEmpresas,
} from "../../api/pedido.api";
import { getEmpresaApi } from "../../api/empresa.api";

export const TablaPedidosState = (props) => {
  const initialPedido = {
    estadoViewPedido: false,
    estadoEntregarPedido: false,
    estadoNumPedido: false,
    estadoAddLogo: false,
    verPedido: {},
    entregarPedido: {},
    pedidos: [],
    resultado: [],
  };

  const [tablaPedidos, dispatch] = useReducer(
    TablaPedidosReducer,
    initialPedido
  );

  const getPedidoAdminEmpresa = async (nombre) => {
    const query = await getPedidosEmpresas(nombre);
    if (query.data.message === "Ventas encontradas exitosamente.") {
      dispatch({
        type: SET_PEDIDO,
        payload: query.data.data,
      });
    } else {
      return false;
    }
  };

  const getPedidoVendedor = async () => {
    const query = await getPedidos();
    if (query.data.message == "Venta encontrada exitosamente.") {
      dispatch({
        type: SET_PEDIDO,
        payload: query.data.data,
      });
    } else {
      return false;
    }
  };

  const setOpenViewPedido = (bool, pedido) => {
    dispatch({
      type: SET_OPEN_PEDIDO_DETAILS,
      payload: { bool, pedido },
    });
  };

  const setEntregarPedido = (pedido) => {
    const query = 
    dispatch({
      type: SET_ENTREGAR_PEDIDO,
      payload: tablaPedidos.pedidos.map((ped) =>
        ped.boleta_id === pedido.boleta_id ? pedido : ped
      ),
    });
  };

  const actualizarEstado = async (pedido) => {
    const query = await putActualizarVenta(pedido);
    if (query.data.message === "Venta actualizada exitosamente.") {
      dispatch({
        type: SET_ACTUALIZAR_ESTADO,
        payload: tablaPedidos.pedidos.map((ped) =>
          ped.boleta_id === pedido.boleta_id ? pedido : ped
        ),
      });
    }
  };


  const setOpenNumPedido = async (bool, pedido) => {
    dispatch({
      type: SET_OPEN_NUM_PEDIDO,
      payload: { bool, pedido },
    });
  };

  const getEmpresaById = async (id) => {
    const query = await getEmpresaApi(id);
    if (query.data.message === "Empresa traido exitosamente.") {
      return {
        nombre: query.data.data.nombre,
      };
    } else {
      return false;
    }
  };

  const setBuscar = (busqueda) => {
    dispatch({
      type: SET_BUSQUEDA,
      payload: tablaPedidos.pedidos.filter((pedido) =>
        pedido.nombre.toLowerCase().includes(busqueda.toLowerCase())
      ),
    });
  };

  const cargaEstado = (estado) => {
    // 0: pendiente, 1: pagada cuota, 2: cuotas aprobadas, 3: pagada webpay, 4: despachada, 5: entregada, 6: anulada, 7: rechazada
    if (estado === 0) {
      return (
        <div className="ml-10 px-2 ml-1 inline-flex items-center justify-center inline-flex items-center  py-0.5 rounded-full text-sm capitalize bg-yellow-100 text-yellow-800">
          pendiente
        </div>
      );
    }
    if (estado === 2) {
      return (
        <div className="ml-10 px-2 ml-1 inline-flex items-center justify-center inline-flex items-center  py-0.5 rounded-full text-sm capitalize bg-green-200 text-green-800">
          aprobada <span className="text-xs font-medium ml-1">(C)</span>
        </div>
      );
    }
    if (estado === 3) {
      return (
        <div className="ml-10 px-2 ml-1 inline-flex items-center justify-center inline-flex items-center  py-0.5 rounded-full text-sm capitalize bg-green-200 text-green-800">
          pagada <span className="text-xs font-medium ml-1">(W)</span>
        </div>
      );
    }
    if (estado === 4) {
      return (
        <div className="ml-10 px-2 ml-1 inline-flex items-center justify-center inline-flex items-center  py-0.5 rounded-full text-sm capitalize bg-green-200 text-green-800">
          despachada
        </div>
      );
    }
    if (estado === 5) {
      return (
        <div className="ml-10 px-2 ml-1 inline-flex items-center justify-center inline-flex items-center  py-0.5 rounded-full text-sm capitalize bg-blue-200 text-blue-800">
          entregado
        </div>
      );
    }
    if (estado === 6) {
      return (
        <div className="ml-10 px-2 ml-1 inline-flex items-center justify-center inline-flex items-center  py-0.5 rounded-full text-sm capitalize bg-gray-200 text-gray-700">
          anulado
        </div>
      );
    }
    if (estado === 7) {
      return (
        <div className="ml-10 px-2 ml-1 inline-flex items-center justify-center inline-flex items-center  py-0.5 rounded-full text-sm capitalize bg-red-200 text-red-800">
          rechazado
        </div>
      );
    }
  };

  return (
    <TablaPedidosContext.Provider
      value={{
        openViewPedido: tablaPedidos.estadoViewPedido,
        openEntregarPedido: tablaPedidos.estadoEntregarPedido,
        openNumPedido: tablaPedidos.estadoNumPedido,
        pedidos: tablaPedidos.pedidos,
        verPedido: tablaPedidos.verPedido,
        entregarPedido: tablaPedidos.entregarPedido,
        setEntregarPedido,
        setOpenViewPedido,
        actualizarEstado,
        setOpenNumPedido,
        getPedidoVendedor,
        getPedidoAdminEmpresa,
        getEmpresaById,
        setBuscar,
        cargaEstado,
      }}
    >
      {props.children}
    </TablaPedidosContext.Provider>
  );
};
