import {
  SET_OPEN_PEDIDO_DETAILS,
  SET_APROBAR,
  SET_RECHAZAR,
  SET_PEDIDO,
  VIEW_PEDIDOS,
  SET_ACTUALIZAR_ESTADO,
  SET_OPEN_NUM_PEDIDO,
  ADD_LOGO,
  SET_BUSQUEDA
} from "../types";

const TablaPedidoReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_OPEN_PEDIDO_DETAILS:
      return {
        ...state,
        estadoViewPedido: payload.bool,
        verPedido: payload.pedido,
      };
    case SET_PEDIDO:
      return {
        ...state,
        pedidos: payload,
        resultado: payload,
      };

    case SET_BUSQUEDA: 
      return {
          ...state,
          resultado : payload
      }

    case SET_ACTUALIZAR_ESTADO:
      return {
        ...state,
        pedidos: payload,
        resultado: payload,
      };

    case VIEW_PEDIDOS:
      return {
        ...state,
        pedidos: payload,
      };

    case SET_OPEN_NUM_PEDIDO:
      return {
        ...state,
        estadoNumPedido: payload.bool,
        entregarPedido: payload.pedido,
      };

    case ADD_LOGO:
      payload.estadoAddLogo = true
      return {
        ...state
      }
  }
};

export default TablaPedidoReducer;
