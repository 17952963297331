import axios from "axios";

export const getPedidos = async (pedido) => {
    return await axios.get(`venta`)
}

export const getPedidosEmpresas = async (nombre) => {
    return await axios.get(`venta/empresa/${nombre}`)
}

export const getPedidosVend = async (pedido) => {
    return await axios.get(`/api/v1/vendedor-ver-pedidos`)
}

export const putActualizarVenta = async (pedido) => {
    return await axios.put(`/venta/actualizar-pedido`, pedido)
}

// export const putRechazarPedido = async (pedido) => {
//     return await axios.put(`/venta/actualizar-pedido`, pedido)
// }