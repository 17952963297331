import { SET_BENEFICIARIO, SET_OPEN_DELETE_BENEFICIARIO, SET_OPEN_AGREGAR_BENEFICIARIO, SET_OPEN_DETALLE_BENEFICIARIO, SET_OPEN_EDITA_BENEFICIARIO, SET_OPEN_IMPORTAR_BENEFICIARIOS, SET_BUSQUEDA, SET_CARGANDO } from "../types";

const TablaBeneficiariosReducer = (state, action) => {

    const {payload, type} = action

    switch(type){
        case SET_OPEN_AGREGAR_BENEFICIARIO:
            return {
                ...state,
                estadoBeneficiario : payload,
            }
        case SET_BENEFICIARIO:
            return {
                ...state,
                beneficiarios : payload,
                resultado : payload
            }

        case SET_CARGANDO:
            return {
                ...state,
                cargando : payload
            }
        case SET_BUSQUEDA: 
            return {
                ...state,
                resultado : payload
            }
        case SET_OPEN_IMPORTAR_BENEFICIARIOS:
            return {
                ...state,
                estadoImportarBeneficiario : payload
            }
        case SET_OPEN_DETALLE_BENEFICIARIO:
            return{
                ...state,
                estadoDetalleBeneficiario : payload.bool,
                detalleBeneficiario : payload.beneficiario == undefined ? {} : payload.beneficiario
            }
        case SET_OPEN_EDITA_BENEFICIARIO:
            return{
                ...state,
                estadoEditaBeneficiario : payload.bool,
                editaBeneficiario : payload.beneficiario == undefined ? {} : payload.beneficiario
            }
        case SET_OPEN_DELETE_BENEFICIARIO:
            return{
                ...state,
                estadoDeleteBeneficiario: payload.bool,
                eliminaBeneficiario: payload.beneficiario 
            }

    }

}

export default TablaBeneficiariosReducer;