import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Login, ActivarCuenta, IngresarCuenta, CatalogoBusqueda, RecuperarContrasena, CambiarContrasena, Empresa1, InicioComercio, CatalogoComercio, ProductoComercio, CarritoComercio, ContrasenaCompra } from './pages';
import { SideBarState } from './context/sideBar/SideBarState'
import { TiendaState } from './context/tienda/TiendaState';
import { SideBar } from './components/dashboardVendedor/sideBar';
import { SideBarAdministracion } from './components/dashboardAdministrador/sideBar';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { getEmpresaApi } from './api/api';
import { useEffect, useState } from 'react';
import ValidaOrden from './pages/tienda/valida';
import CompraRechazada from './pages/tienda/compra-rechazada-cuotas';

function App() {
  axios.defaults.baseURL = 'https://api-beneficio.comercialpuertosur.cl'

  const [empresas, setEmpresas] = useState([])
  
  useEffect(() => {
    async function getEmpresas() {
      const response = await getEmpresaApi()
      setEmpresas(response.data.data)
    }

    getEmpresas()
  }, [])

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <SideBarState>
        <TiendaState>
          <Routes>
              <Route path='/'>
                <Route path='activar-cuenta' element={ <ActivarCuenta /> }/>
                <Route index element={ <Login /> }/>
                  <Route path='vendedor'>
                      <Route index element={ <SideBar url="Inicio" /> }/>
                      <Route path='catalogo' element={ <SideBar url="Catalogos" />} />
                      <Route path='producto' element={ <SideBar url="Productos"/> }/>
                      <Route path='pedidos' element={ <SideBar url="Pedidos"/> }/>
                      {empresas.length > 0 && empresas.map(empresa => (
                        <Route path={empresa.nombre} key={empresa.id} element={ <SideBar url={empresa.nombre}/> }/>
                      ))}
                  </Route>
                <Route path='administracion'>
                  <Route index element={ <SideBarAdministracion url="Beneficiarios" /> }/>
                  <Route path='pedidos' element={ <SideBarAdministracion url="Pedidos" /> }/>
                </Route>
                { empresas.length > 0 && empresas.map(empresa => (
                  <Route path={empresa.nombre} key={empresa.id}>
                    <Route index element={ <InicioComercio empresa={empresa} /> } />
                    <Route path='catalogo/:id' element={ <CatalogoComercio empresa={empresa} /> }/>
                    <Route path='producto/:id' element={ <ProductoComercio empresa={empresa} /> }/>
                    <Route path='carrito' element={ <CarritoComercio empresa={empresa} /> }/>
                    <Route path='buscar' element={ <CatalogoBusqueda empresa={empresa} /> }/>
                    <Route path='datos-compra' element={ <ContrasenaCompra empresa={empresa} /> }/>
                    <Route path='activar-cuenta' element={ <ActivarCuenta /> }/>
                    <Route path='recuperar-contrasena' element={ <RecuperarContrasena /> }/>
                    <Route path='cambiar-constrasena' element={ <CambiarContrasena /> }/>
                    <Route path='ingresar' element={ <IngresarCuenta/> }/>
                    <Route path='valida/*' element={ <ValidaOrden /> }/>
                    <Route path='pago-rechazado' element={ <CompraRechazada />} />
                  </Route>
                ))}
              </Route>
            </Routes>
        </TiendaState>
      </SideBarState>
    </>
      
  );
}

export default App;