import { Form, Formik } from "formik"
import { useContext, useEffect } from "react"
import TablaCatalogosContext from "../../../context/tablaCatalogo/TablaCatalogoContext"
import TablaProductosContext from "../../../context/tablaProducto/TablaProductoContext"
  
  export default function FormAnexaCatalogo() {

    const {getProducto, productos } = useContext(TablaProductosContext)
    const {anexaCatalogo, setOpenAnexaCatalogo, anexaCatalogoProductos, getCatProd, catProd} = useContext(TablaCatalogosContext)

    useEffect(() => {
        getProducto()
        getCatProd(anexaCatalogo.id)
      }, [])

    return (
      <Formik
        initialValues={{
            productos : [],
            id_catalogo : anexaCatalogo.id
        }}

        onSubmit = { async (values) => {
            await anexaCatalogoProductos(values)
            setOpenAnexaCatalogo(false)
        }}
      
      >
        {({values}) => (
            <Form>
            <fieldset>
                <legend className="text-lg font-medium text-gray-900">Seleccione productos</legend>
                <div className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200">
                {productos.map((producto, productoIdx) => (
                    <div key={productoIdx} className="relative flex items-start py-4">
                    <div className="min-w-0 flex flex-1 text-sm items-center">
                        <img src={producto.imagen} className="h-20" />
                        <label htmlFor={`producto-${producto.id}`} className="text-xl ml-5 select-none font-medium text-gray-700">
                        {producto.nombre}
                        </label>
                    </div>
                    <div className="ml-3 flex h-5 items-center">
                        <input
                        id={`producto-${producto.id}`}
                        name={`producto-${producto.id}`}
                        onClick = {() => { values.productos.push(producto.id) }}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                    </div>
                    </div>
                ))}
                </div>
            </fieldset>
            <div className="mt-5 sm:mt-4 pt-5 sm:flex sm:flex-row-reverse">
                    <div className="flex justify-end">
                        <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpenAnexaCatalogo(false, {})}
                        >
                        Cancelar
                        </button>
                        <button
                        type="submit"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                        Guardar
                        </button>
                    </div>
                </div>
          </Form>
        )}
      </Formik>
    )
  }