import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { cambiarPass } from "../api/cambiarpass.api";
import { REACT_APP_API } from "../service/env";
import { desencriptarDatos } from "../service/AES";
import toast from "react-hot-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function CambiarContrasena() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const empresa = JSON.parse(desencriptarDatos("empresa"));

  useEffect(() => {
    document.documentElement.className = "h-full bg-gray-50";
    document.body.className = "h-full";
    document.getElementById("root").className =
      "flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8";
  }, []);

  return (
    <>
      <div className="flex absolute top-0 left-0 p-5">
        <a
          href={"/" + empresa.nombre}
          className="text-sm"
          style={{
            color: empresa.color_primario ? empresa.color_primario : "#000",
          }}
        >
          <span aria-hidden="true" className="p-2">
            {" "}
            &larr;
          </span>
          Inicio
        </a>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-20 w-auto flex justify-center"
          alt="logo"
          src={REACT_APP_API + "/" + empresa.imagen_logo}
        />
        <p className="mt-8 font-semibold text-center ">Cambiar Contraseña</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            initialValues={{
              tokenPassword: token,
              password: "",
              repeatPassword: "",
            }}
            onSubmit={async (values) => {
              const res = await cambiarPass(values);

              if (res.data.state === "success") {
                window.location.href = "/ingresar";
              } else {
                toast.error(res.data.message);
              }
            }}
            validationSchema={Yup.object({
              password: Yup.string().required("Nueva contraseña es requerida."),
              repeatPassword: Yup.string()
                .required("Repetir contraseña es requerido.")
                .oneOf(
                  [Yup.ref("password"), null],
                  "Las contraseñas no coinciden"
                ),
            })}
          >
            {({ errors, touched }) => (
              <Form className="space-y-6" action="#" method="POST">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nueva Contraseña
                  </label>
                  <div className="mt-1">
                    <Field
                      name="password"
                      type="password"
                      className={classNames(
                        errors.password == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.password
                          ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.password && (
                      <div className="text-red-500 text-sm">
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="repeatPassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Repetir Contraseña
                  </label>
                  <div className="mt-1">
                    <Field
                      name="repeatPassword"
                      type="password"
                      className={classNames(
                        errors.repeatPassword == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.repeatPassword
                          ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.repeatPassword && (
                      <div className="text-red-500 text-sm">
                        {errors.repeatPassword}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Aceptar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
