import { useReducer } from "react";
import TablaBeneficiariosReducer from "./TablaBeneficiariosReducer";
import TablaBeneficiariosContext from "./TablaBeneficiariosContext";
import {
  SET_BENEFICIARIO,
  SET_BUSQUEDA,
  SET_CARGANDO,
  SET_OPEN_AGREGAR_BENEFICIARIO,
  SET_OPEN_DELETE_BENEFICIARIO,
  SET_OPEN_DETALLE_BENEFICIARIO,
  SET_OPEN_EDITA_BENEFICIARIO,
  SET_OPEN_IMPORTAR_BENEFICIARIOS,
} from "../types";
import {
  addBeneficiarioApi,
  addBeneficiarioExcelApi,
  deleteBeneficiarioApi,
  editaBeneficiarioApi,
  getBeneficiariosEmpresaApi,
} from "../../api/beneficiarios.api";

export const TablaBeneficiariosState = (props) => {
  const initialBeneficiario = {
    estadoBeneficiario: false,
    estadoImportarBeneficiario: false,
    estadoDetalleBeneficiario: false,
    estadoEditaBeneficiario: false,
    estadoDeleteBeneficiario: false,
    editaBeneficiario: {},
    detalleBeneficiario: {},
    eliminaBeneficiario: {},
    beneficiarios: [],
    resultado : [],
    cargando : false
  };

  const [tablaBeneficiarios, dispatch] = useReducer(
    TablaBeneficiariosReducer,
    initialBeneficiario
  );

  const setOpenAgregarBeneficiario = (bool) => {
    dispatch({
      type: SET_OPEN_AGREGAR_BENEFICIARIO,
      payload: bool,
    });
  };

  const setOpenEditaBeneficiario = (bool, beneficiario) => {
    dispatch({
      type: SET_OPEN_EDITA_BENEFICIARIO,
      payload: { bool, beneficiario },
    });
  };

  const setOpenDetalleBeneficiario = (bool, beneficiario) => {
    dispatch({
      type: SET_OPEN_DETALLE_BENEFICIARIO,
      payload: { bool, beneficiario },
    });
  };

  const deleteBeneficiario = async (id) => {
    if (id.id !== -1) {
      const query = await deleteBeneficiarioApi(id.rut);
      if (query.data.message === "Beneficiario eliminado exitosamente.") {
        dispatch({
          type: SET_BENEFICIARIO,
          payload: tablaBeneficiarios.beneficiarios.filter((b) => {
            if (b.id != id.id) return b;
          }),
        });
        return true;
      } else {
        return false;
      }
    }
  };

  const importaBeneficiarios = async (values) => {
    const query = await addBeneficiarioExcelApi(values);
    if (query.data.message == "Beneficiario agregado exitosamente.") {
      return true;
    } else {
      return false;
    }
  };

  const getBeneficiarios = async (id_empresa) => {
    const beneficiarios = await getBeneficiariosEmpresaApi(id_empresa);
    if (beneficiarios.data.message === "Beneficiarios traidos exitosamente.") {
      dispatch({
        type: SET_BENEFICIARIO,
        payload: beneficiarios.data.data,
      });
    } else {
      return false;
    }
  };

  const addBeneficiario = async (beneficiario) => {
    const insertBeneficiario = await addBeneficiarioApi(beneficiario);
    if (
      insertBeneficiario.data.message ===
      "Beneficiario agregado exitosamente."
    ) {
      dispatch({
        type: SET_BENEFICIARIO,
        payload: [...tablaBeneficiarios.beneficiarios, {
          ...beneficiario,
          id : insertBeneficiario.data.data.id
        }]
      });
      return true;
    } else {
      return false;
    }
  };

  const editaBeneficiarioForm = async (beneficiario, id) => {
    const query = await editaBeneficiarioApi(beneficiario, id);
    if (query.data.message == "Beneficiario editado exitosamente.") {
      dispatch({
        type: SET_BENEFICIARIO,
        payload: tablaBeneficiarios.beneficiarios.map((b) => {
          if (b.id == id) return beneficiario;
          else return b;
        }),
      });
      return true;
    } else {
      return false;
    }
  };

  const setOpenImportarBeneficiarios = (bool) => {
    dispatch({
      type: SET_OPEN_IMPORTAR_BENEFICIARIOS,
      payload: bool,
    });
  };

  const setOpenDeleteBeneficiario = (bool, beneficiario) => {
    dispatch({
      type: SET_OPEN_DELETE_BENEFICIARIO,
      payload: { bool, beneficiario },
    });
  };

  const setBusqueda = (busqueda) => {
    dispatch({
      type: SET_BUSQUEDA,
      payload: tablaBeneficiarios.beneficiarios.filter(
        (beneficiarios) =>
          beneficiarios.nombres
            .toLowerCase()
            .includes(busqueda.toLocaleLowerCase()) ||
          beneficiarios.apellido_paterno
            .toLowerCase()
            .includes(busqueda.toLocaleLowerCase()) ||
          beneficiarios.rut.toString().includes(busqueda.toLocaleLowerCase())
      ),
    });
  }

  const setCargando = (bool) => {
    dispatch({
      type: SET_CARGANDO,
      payload: bool
    })
  }

  return (
    <TablaBeneficiariosContext.Provider
      value={{
        beneficiarios: tablaBeneficiarios.beneficiarios,
        detalleBeneficiario: tablaBeneficiarios.detalleBeneficiario,
        openDetalleBeneficiario: tablaBeneficiarios.estadoDetalleBeneficiario,
        openDeleteBeneficiario: tablaBeneficiarios.estadoDeleteBeneficiario,
        openAgregarBeneficiario: tablaBeneficiarios.estadoBeneficiario,
        openImportarBeneficiarios:
          tablaBeneficiarios.estadoImportarBeneficiario,
        openEditaBeneficiario: tablaBeneficiarios.estadoEditaBeneficiario,
        editaBeneficiario: tablaBeneficiarios.editaBeneficiario,
        eliminaBeneficiario: tablaBeneficiarios.eliminaBeneficiario,
        cargando : tablaBeneficiarios.cargando,
        resultado : tablaBeneficiarios.resultado,
        setOpenAgregarBeneficiario,
        addBeneficiario,
        setOpenImportarBeneficiarios,
        getBeneficiarios,
        editaBeneficiarioForm,
        importaBeneficiarios,
        deleteBeneficiario,
        setOpenDetalleBeneficiario,
        setOpenEditaBeneficiario,
        setOpenDeleteBeneficiario,
        setBusqueda,
        setCargando
      }}
    >
      {props.children}
    </TablaBeneficiariosContext.Provider>
  );
};
