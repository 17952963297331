import { Fragment, useContext, useEffect } from "react";
import NavBar from "../../components/tienda/navBar";
import TiendaContext from "../../context/tienda/TiendaContext";
import { useLocation } from "react-router-dom";
import { REACT_APP_API } from "../../service/env";

export function CatalogoComercio({ empresa }) {
  const { productos, getProductoCatalogo } = useContext(TiendaContext);

  var idCatalogo = useLocation().pathname.split("/")[3];
  useEffect(() => {
    getProductoCatalogo(idCatalogo);
  }, []);

  return (
    <div className="bg-gray-50">
      <NavBar empresa={empresa} />
      <div>
        <main>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="py-20 text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                Productos
              </h1>
              {/* <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
                Ropa para un dia de oficina.
              </p> */}
            </div>
            <section aria-labelledby="products-heading" className="mt-8 pb-24">
              <h2 id="products-heading" className="sr-only">
                Productos
              </h2>

              <div className="bg-white">
                <div className="mx-auto max-w-2xl py-8 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                    {productos.map((product) => (
                      <div key={product.id} className="group relative">
                        <div className="min-h-60 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-60">
                          <img
                            src={REACT_APP_API + '/' + product.imagen}
                            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                          />
                        </div>
                        <div className="mt-4 flex justify-between">
                          <div>
                            <h3 className="text-sm text-gray-700">
                              <a href={'/' + empresa.nombre + "/producto/" + product.id}>
                                <span
                                  aria-hidden="true"
                                  className="absolute inset-0"
                                />
                                {product.nombre}
                              </a>
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              {product.colores}
                            </p>
                          </div>
                          <p className="text-sm font-medium text-gray-900">
                            {product.precio?.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            })}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
}
