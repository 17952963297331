import { useContext, useEffect, useState } from "react";
import { RadioGroup, Tab } from "@headlessui/react";
import TiendaContext from "../../context/tienda/TiendaContext";
import NavBar from "../../components/tienda/navBar";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { REACT_APP_API } from "../../service/env";
import { Switch } from "@headlessui/react";
import { obtenerRutaImagen } from "../../image/Imagenes";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function ProductoComercio({ empresa }) {
  const [cargando, setCargando] = useState(false);
  const [logo, setLogo] = useState(false);

  const { producto, getDetalleProductoEmpresa, setCarrito } =
    useContext(TiendaContext);
  const colores = producto.colores
    ? producto.colores.split(",").map((x) => {
        return {
          name: x,
          selectedColor: true,
        };
      })
    : [];

  const tallas = producto.tallas
    ? producto.tallas.split(",").map((x) => {
        return {
          name: x,
          selectedSize: true,
        };
      })
    : [];

  const [selectedColor, setSelectedColor] = useState({});

  const [selectedSize, setSelectedSize] = useState({});

  var idProducto = useLocation().pathname.split("/")[3];

  useEffect(() => {
    getDetalleProductoEmpresa(idProducto);
  }, []);

  var initialValues = {
    id: producto.id,
    nombre: "",
    precio: "",
    color: "",
    talla: "",
    imageSrc: "",
    cantidad: 1,
    logo: false,
  };

  return (
    <>
      <div className="bg-white">
        <NavBar empresa={empresa} />
        <main className="mx-auto mt-8 max-w-2xl px-4 pb-16 sm:px-6 sm:pb-24 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
            <div className="lg:col-span-5 lg:col-start-8">
              <div className="flex justify-between">
                <h1 className="text-xl font-medium text-gray-900">
                  {producto.nombre}
                </h1>
                <p className="text-xl font-bold text-gray-900">
                  {producto.precio?.toLocaleString("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  })}
                </p>
              </div>
            </div>

            <div className="mt-8 lg:col-span-7 lg:mt-0">
              <h2 className="sr-only">Images</h2>

              <div className="lg:grid lg:items-start lg:gap-x-8">
                {/* Image gallery */}
                <Tab.Group as="div" className="flex flex-col-reverse">
                  {/* Image selector */}
                  <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                    <Tab.List className="grid grid-cols-4 gap-6">
                        <Tab
                          className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                        >
                          {({ selected }) => (
                            <>
                              <span className="sr-only">Imagen Producto</span>
                              <span className="absolute inset-0 overflow-hidden rounded-md">
                                <img src={REACT_APP_API + '/' + producto.imagen} alt="" className="h-full w-full object-cover object-center max-h-96" />
                              </span>
                              <span
                                className={classNames(
                                  selected ? 'ring-indigo-500' : 'ring-transparent',
                                  'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </Tab>
                        {producto.nombre && obtenerRutaImagen(producto.nombre) !== null && (
                          <Tab
                            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                          >
                            {({ selected }) => (
                              <>
                                <span className="sr-only">Imagen Producto</span>
                                <span className="absolute inset-0 overflow-hidden rounded-md">
                                  <img src={obtenerRutaImagen(producto.nombre)} alt="" className="h-full w-full object-cover object-center max-h-96" />
                                </span>
                                <span
                                  className={classNames(
                                    selected ? 'ring-indigo-500' : 'ring-transparent',
                                    'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </Tab>
                        )}
                    </Tab.List>
                  </div>

                  <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                    <Tab.Panel>
                      <img
                        src={REACT_APP_API + '/' + producto.imagen}
                        alt="Imagen Producto"
                        className="h-full w-full object-cover object-center sm:rounded-lg"
                      />
                    </Tab.Panel>
                    {producto.nombre && obtenerRutaImagen(producto.nombre) !== null && (
                      <Tab.Panel>
                        <img
                          src={obtenerRutaImagen(producto.nombre)}
                          alt="Imagen Producto"
                          className="h-full w-full object-cover object-center sm:rounded-lg"
                        />
                      </Tab.Panel>
                    )}
                    
                  </Tab.Panels>
                </Tab.Group>
                {/* <img
                  src={REACT_APP_API + '/' + producto.imagen}
                  alt="producto imagen"
                  className="lg:block rounded-lg max-h-96"
                /> */}
              </div>
            </div>

            <div className="mt-8 lg:col-span-5">
              <div>
                <div className="mt-8">
                  <div className="flex items-center justify-between">
                    <h2 className="text-sm font-medium text-gray-900">
                      Colores
                    </h2>
                  </div>

                  <RadioGroup
                    value={selectedColor}
                    onChange={setSelectedColor}
                    className="mt-2"
                  >
                    <RadioGroup.Label className="sr-only">
                      {" "}
                      Choose a color{" "}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                      {colores.map((color) => (
                        <RadioGroup.Option
                          key={color.name}
                          value={color}
                          className={({ active, checked }) =>
                            classNames(
                              active
                                ? "ring-2 ring-offset-2 ring-indigo-500"
                                : "",
                              color.name === selectedColor.name
                                ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                              "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                            )
                          }
                        >
                          <RadioGroup.Label as="span">
                            {color.name}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <div className="mt-8">
                  {producto.tiene_talla === 1 && (
                    <>
                      <div className="flex items-center justify-between">
                        <h2 className="text-sm font-medium text-gray-900">
                          Tallas
                        </h2>
                      </div>
                      <RadioGroup
                        value={selectedSize}
                        onChange={setSelectedSize}
                        className="mt-2"
                      >
                        <RadioGroup.Label className="sr-only">
                          {" "}
                          Choose a size{" "}
                        </RadioGroup.Label>
                        <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                          {tallas.map((size) => (
                            <RadioGroup.Option
                              key={size.name}
                              value={size}
                              className={({ active, checked }) =>
                                classNames(
                                  active
                                    ? "ring-2 ring-offset-2 ring-indigo-500"
                                    : "",
                                  size.name === selectedSize.name
                                    ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                  "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                                )
                              }
                            >
                              <RadioGroup.Label as="span">
                                {size.name}
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </>
                  )}
                  <div className="mt-8">
                    {producto.tiene_medida === 1 && (
                      <>
                        <div className="flex items-center justify-between">
                          <h2 className="text-sm font-medium text-gray-900">
                            Medidas
                          </h2>
                        </div>
                        <div className="mt-2 text-sm font-medium text-gray-900 grid grid-cols-3 gap-3">
                          <p className="mb-1 border rounded-md py-3 px-3 ">Alto: {producto.alto}cm </p>
                          <p className="mb-1 border rounded-md py-3 px-3 ">Ancho: {producto.ancho}cm </p>
                          <p className="mb-1 border rounded-md py-3 px-3 ">Largo: {producto.largo}cm </p>
                        </div>
                      </>
                    )}
                  </div>
                  { producto.logo === 1 && (
                    <div className="text-sm font-medium text-red-500 mt-5">
                      * El producto tiene logo oblligatorio de la empresa.
                    </div>
                  )}
                  {producto.logo === 0 && producto.tiene_talla !== 0 && (
                    <>
                    <div className="sm:grid sm:grid-cols-6 sm:items-start sm:pt-5">
                      <label
                          htmlFor="logo"
                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          ¿Logo?
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <Switch
                            checked={logo}
                            onChange={setLogo}
                            onClick={() => {
                              setLogo(logo ? 0 : 1);
                            }}
                            className={classNames(
                              logo ? "bg-indigo-600" : "bg-gray-200",
                              "mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            )}
                          >
                            <span className="sr-only">Use setting</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                logo ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </div>
                    </div>
                    </>
                  )}
                </div>

                <button
                  disabled={cargando}
                  className="disabled:bg-gray-200 mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-base font-bold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={async () => {
                    if (Object.entries(selectedColor).length === 0) {
                      toast.error("Debe seleccionar un color.");
                    } else if (
                      Object.entries(selectedSize).length === 0 &&
                      producto.tallas !== "" && producto.tiene_talla === 1
                    ) {
                      toast.error("Debe selecionar una talla.");
                    } else {
                      setCargando(true);
                      initialValues.color = selectedColor.name;
                      initialValues.talla = selectedSize.name;
                      initialValues.nombre = producto.nombre;
                      initialValues.precio = producto.precio;
                      initialValues.imageSrc = producto.imagen;
                      {
                        producto.logo
                          ? (initialValues.logo = producto.logo)
                          : logo
                          ? (initialValues.logo = logo)
                          : (initialValues.logo = false);
                      }
                      const addproduct = await setCarrito(initialValues);
                      addproduct
                        ? toast.success(
                            "Producto agregado al carrito correctamente."
                          )
                        : toast.error("No se pudo agregar el producto");
                        
                      toast.promise(
                        setTimeout(() => {
                          window.location.href =
                            window.location.href.split("/producto")[0];
                        }, "1000"),
                        {
                          loading: "Redirigiendo al inicio...",
                        }
                      );

                      setCargando(false);
                    }
                  }}
                >
                  Agregar al carrito
                </button>
              </div>

              <div className="mt-10">
                <h2 className="text-sm font-medium text-gray-900">
                  Descripción
                </h2>

                <div
                  className="prose prose-sm mt-4 text-gray-500"
                >
                  {producto.descripcion}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
