import { useContext, useEffect, useState } from "react";
import NavBar from "../../components/tienda/navBar";
import TiendaContext from "../../context/tienda/TiendaContext";
import { REACT_APP_API } from "../../service/env";
export function CatalogoBusqueda({empresa}) {
  const [busqueda, setBusqueda] = useState("");
  const { getProductosEmpresa, productos } = useContext(TiendaContext);

  useEffect(() => {
    getProductosEmpresa(empresa.id);
  }, []);

  let resultado = [];

  const search = (e) => {
    setBusqueda(e.target.value);
  };

  if (!busqueda) {
    resultado = productos;
  } else {
    resultado = productos.filter((product) =>
      product.nombre.toLowerCase().includes(busqueda.toLocaleLowerCase())
    );
  }

  return (
    <>
      <NavBar name={empresa.nombre} empresa={empresa} />
      <div className="bg-gray-50">
        <div>
          <main>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="py-20 text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                  Productos
                </h1>
              </div>

              <form>
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    value={busqueda}
                    type="search"
                    id="default-search"
                    className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Buscar..."
                    required
                    onChange={search}
                  />
                </div>
              </form>
              {/* Product grid */}
              <section
                aria-labelledby="products-heading"
                className="mt-8 pb-24"
              >
                <h2 id="products-heading" className="sr-only">
                  Products
                </h2>

                <div className="bg-white">
                  <div className="mx-auto max-w-2xl py-8 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                      {resultado.map((product) => (
                        <div key={product.id} className="group relative">
                          <div className="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-80">
                            <img
                              src={REACT_APP_API + '/' + product.imagen}
                              alt="imagen producto"
                              className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                            />
                          </div>
                          <div className="mt-4 flex justify-between">
                            <div>
                              <h3 className="text-sm text-gray-700">
                                <a
                                  href={ "/" + empresa.nombre + "/producto/" + product.id}
                                >
                                  <span
                                    aria-hidden="true"
                                    className="absolute inset-0"
                                  />
                                  {product.nombre}
                                </a>
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                {product.color}
                              </p>
                            </div>
                            <p className="text-sm font-medium text-gray-900">
                              {product.precio?.toLocaleString("es-CL", {
                                style: "currency",
                                currency: "CLP",
                              })}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
