export default function formatearRut(rut) {
  // Eliminar puntos y guión
  rut = rut.replace(/\./g, '').replace(/-/g, '');

  // Obtener dígito verificador
  const dv = rut.slice(-1);

  // Obtener números del RUT sin dígito verificador
  const rutSinDv = rut.slice(0, -1);

  // Agregar puntos separadores de miles
  let rutFormateado = rutSinDv.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Agregar guión separador de dígito verificador
  rutFormateado += '-' + dv;

  return rutFormateado;
};