import { useReducer } from "react";
import TablaProductosReducer from "./TablaProductoReducer";
import TablaProductosContext from "./TablaProductoContext";
import {
  SET_OPEN_ADDPRODUCTO,
  SET_OPEN_DELETEPRODUCTO,
  SET_OPEN_UPDATEPRODUCTO,
  SET_PRODUCTO,
  DELETE_PRODUCTO,
  SET_BUSQUEDA,
  ADD_PRODUCTO,
} from "../types";
import {
  addProductoApi,
  deleteProductoApi,
  editProductoApi,
  getProductoApi,
  getProductosEmpresaApi,
} from "../../api/producto.api";

export const TablaProductosState = (props) => {
  const initialTablaProducto = {
    estadoAddProducto: false,
    estadoUpdateProducto: false,
    estadoDeleteProducto: false,
    editaProducto: {},
    eliminaProducto: {},
    resultado: [],
    productos: [],
  };

  const [tablaProductos, dispatch] = useReducer(
    TablaProductosReducer,
    initialTablaProducto
  );

  const setOpenAddProducto = (bool) => {
    dispatch({
      type: SET_OPEN_ADDPRODUCTO,
      payload: bool,
    });
  };

  const setOpenUpdateProducto = (bool, id) => {
    dispatch({
      type: SET_OPEN_UPDATEPRODUCTO,
      payload: { bool, id },
    });
  };
  const setOpenDeleteProducto = (bool, id) => {
    dispatch({
      type: SET_OPEN_DELETEPRODUCTO,
      payload: { bool, id },
    });
  };

  const getProducto = async () => {
    const productos = await getProductoApi();
    if (productos.data.message === "Productos traidos exitosamente.") {
      dispatch({
        type: SET_PRODUCTO,
        payload: productos.data.data,
      });
    } else {
      return false;
    }
  };
  const setBusqueda = (busqueda) => {
    dispatch({
      type: SET_BUSQUEDA,
      payload: tablaProductos.productos.filter(
        (productos) =>
          productos.nombre
            .toLowerCase()
            .includes(busqueda.toLocaleLowerCase()) ||
          productos.descripcion
            .toLowerCase()
            .includes(busqueda.toLocaleLowerCase()) ||
          productos.colores.toLowerCase().includes(busqueda.toLocaleLowerCase())
      ),
    });
  };

  const addProducto = async (producto) => {
    const query = await addProductoApi(producto);
    if (query.data.message === "Producto agregado exitosamente.") {
      dispatch({
        type: ADD_PRODUCTO,
        payload: [
          ...tablaProductos.productos,
          { ...producto, id: query.data.data.id },
        ],
      });
      return true;
    } else {
      return false;
    }
  };

  const updateProducto = async (producto) => {
    const query = await editProductoApi(producto);
    if (query.data.message == "Producto editado exitosamente.") {
      await getProducto();
      return true;
    } else {
      return false;
    }
  };

  const deleteProducto = async (id) => {
    const producto = await deleteProductoApi(id);

    if (producto.data.message === "Producto eliminado exitosamente.") {
      dispatch({
        type: DELETE_PRODUCTO,
        payload: id,
      });
      return true;
    } else {
      return false;
    }
  };

  const getProductosEmpresa = async (id) => {
    const productos = await getProductosEmpresaApi(id);
    if (productos.data.message === "Productos traidos exitosamente.") {
      dispatch({
        type: SET_PRODUCTO,
        payload: productos.data.data,
      });
    } else {
      return false;
    }
  };

  return (
    <TablaProductosContext.Provider
      value={{
        openAddProducto: tablaProductos.estadoAddProducto,
        openUpdateProducto: tablaProductos.estadoUpdateProducto,
        openDeleteProducto: tablaProductos.estadoDeleteProducto,
        resultado: tablaProductos.resultado,
        productos: tablaProductos.productos,
        editaProducto: tablaProductos.editaProducto,
        eliminaProducto: tablaProductos.eliminaProducto,
        setOpenAddProducto,
        setOpenUpdateProducto,
        setOpenDeleteProducto,
        getProducto,
        addProducto,
        updateProducto,
        deleteProducto,
        getProductosEmpresa,
        setBusqueda
      }}
    >
      {props.children}
    </TablaProductosContext.Provider>
  );
};
