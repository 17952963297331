import { useContext, useEffect, useState } from "react";
import { ScaleIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon, CreditCardIcon } from "@heroicons/react/20/solid";
import SideBarContext from "../../context/sideBar/SideBarContext";
import TablaPedidosContext from "../../context/tablaPedidos/TablaPedidoContext";
import moment from "moment";

const statusStyles = [
  "bg-yellow-100 text-yellow-800",
  "bg-green-100 text-green-800",
  "bg-green-100 text-green-800",
  "bg-green-100 text-green-800",
  "bg-green-100 text-green-800",
  "bg-blue-100 text-blue-800",
  "bg-gray-100 text-gray-800",
  "bg-red-100 text-red-800",
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function InicioVendedor() {
  const { getEmpresa, empresas, getEstadisticasEmpresas, estadisticas } =
    useContext(SideBarContext);
  const { getPedidoVendedor, pedidos } = useContext(TablaPedidosContext);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * 5;
  const indexOfFirstItem = indexOfLastItem - 5;
  const currentItems = pedidos?.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const resultsStart = indexOfFirstItem + 1;
  const resultsEnd = Math.min(indexOfLastItem, pedidos?.length);

  useEffect(() => {
    document.documentElement.className = "h-full bg-gray-100";
    document.body.className = "h-full";
    getEmpresa();
    getEstadisticasEmpresas();
    getPedidoVendedor();
  }, []);

  return (
    <>
      <main className="flex-1">
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="mt-8">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Vista Previa
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  {empresas?.map((card) => (
                    <div
                      key={card.id}
                      className="overflow-hidden rounded-lg bg-white shadow"
                    >
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <ScaleIcon className="h-6 w-6 text-gray-400" />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">
                                {card.nombre.toUpperCase()}
                              </dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">
                                  {estadisticas.length > 0 ? estadisticas?.map((estadistica) =>
                                    estadistica.empresa_nombre ===
                                    card.nombre ? (
                                      <p key={estadistica.empresa_nombre}>
                                        {parseInt(
                                          estadistica.venta_mensual
                                        ).toLocaleString("es-CL", {
                                          style: "currency",
                                          currency: "CLP",
                                        })}
                                      </p>
                                    ) : null
                                  ): "$0"}

                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <a
                            href={`/vendedor/${card.nombre}`}
                            className="font-medium text-cyan-700 hover:text-cyan-900"
                          >
                            Ver Pedidos
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                Ultimas compras
              </h2>

              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden text-xs">
                <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                  {currentItems.map((pedidos) => (
                    <li key={pedidos.boleta_id}>
                      <span className="block bg-white px-4 py-4 hover:bg-gray-50">
                        <span className="flex items-center space-x-4">
                          <span className="flex flex-1 justify-between space-x-2 truncate">
                            {pedidos.pago_con_cuotas === false ? (
                              <CreditCardIcon
                                className="flex-shrink-0 h-5 w-5 text-green-400 group-hover:text-green-500"
                                aria-hidden="true"
                              />
                            ) : (
                              <BanknotesIcon
                                className="flex-shrink-0 h-5 w-5 text-green-400 group-hover:text-green-500"
                                aria-hidden="true"
                              />
                            )}
                            <p className="truncate text-gray-500 group-hover:text-gray-900">
                              {pedidos.beneficiario_nombres} {" "}
                              {pedidos.beneficiario_apellido_paterno} {" "}
                              {pedidos.beneficiario_apellido_materno}
                            </p>
                            <span className="flex flex-col text-gray-500">
                              <span>
                                <span className="font-medium text-gray-900">
                                  {pedidos.total.toLocaleString("es-CL", {
                                    style: "currency",
                                    currency: "CLP",
                                  })}
                                </span>{" "}
                                {pedidos.currency}
                              </span>
                              <time dateTime={pedidos.fecha}>
                                {moment(pedidos.fecha).format("DD/MM/YYYY")}
                                <div className="text-xs">
                                  {moment(pedidos.fecha).format("HH:mm:ss")}
                                </div>
                              </time>
                            </span>
                            <span className="whitespace-nowrap px-6 py-4 text-gray-500 md:block">
                              <span
                                className={classNames(
                                  statusStyles[pedidos.boleta_estado],
                                  "inline-flex items-center px-2.5 py-0.5 rounded-full font-medium capitalize"
                                )}
                              >
                                {pedidos.boleta_estado === 0 && "Pendiente"}
                                {pedidos.boleta_estado === 1 && "Pagada"}
                                {pedidos.boleta_estado === 2 &&
                                  "Cuotas Aprobadas"}
                                {pedidos.boleta_estado === 3 && "Pagada"}
                                {pedidos.boleta_estado === 4 && "Despachada"}
                                {pedidos.boleta_estado === 5 && "Entregada"}
                                {pedidos.boleta_estado === 6 && "Anulada"}
                                {pedidos.boleta_estado === 7 && "Rechazada"}
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>

                <nav
                  className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 text-xs"
                  aria-label="Pagination"
                >
                  <div className="flex flex-1 justify-between">
                    <button
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 hover:text-gray-500"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      Anterior
                    </button>
                    <span className="mt-2 ml-2 text-gray-700 text-center">
                      {resultsStart} - {resultsEnd} Resultados de{" "}
                      {pedidos?.length}
                    </span>
                    <button
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 hover:text-gray-500"
                      onClick={nextPage}
                      disabled={indexOfLastItem >= pedidos?.length}
                    >
                      Siguiente
                    </button>
                  </div>
                </nav>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th
                              className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Trasacción
                            </th>
                            <th
                              className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Monto
                            </th>
                            <th
                              className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                              scope="col"
                            >
                              Estado
                            </th>
                            <th
                              className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              scope="col"
                            >
                              Fecha
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {currentItems?.map((pedidos) => (
                            <tr
                              key={pedidos.boleta_id}
                              className="bg-white hover:bg-slate-50"
                            >
                              <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                <div className="flex">
                                  <span className="group inline-flex space-x-2 truncate text-sm">
                                    {pedidos.pago_con_cuotas === false ? (
                                      <CreditCardIcon
                                        className="flex-shrink-0 h-5 w-5 text-green-400 group-hover:text-green-500"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <BanknotesIcon
                                        className="flex-shrink-0 h-5 w-5 text-green-400 group-hover:text-green-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <p className="truncate text-gray-500 group-hover:text-gray-900">
                                      {pedidos.beneficiario_nombres} {" "}
                                      {pedidos.beneficiario_apellido_paterno} {" "}
                                      {pedidos.beneficiario_apellido_materno}
                                    </p>
                                  </span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {pedidos.total.toLocaleString("es-CL", {
                                    style: "currency",
                                    currency: "CLP",
                                  })}
                                </span>
                              </td>
                              <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                <span
                                  className={classNames(
                                    statusStyles[pedidos.boleta_estado],
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium capitalize"
                                  )}
                                >
                                  {pedidos.boleta_estado === 0 && "Pendiente"}
                                  {pedidos.boleta_estado === 1 && "Pagada"}
                                  {pedidos.boleta_estado === 2 &&
                                    "Cuotas Aprobadas"}
                                  {pedidos.boleta_estado === 3 && "Pagada"}
                                  {pedidos.boleta_estado === 4 && "Despachada"}
                                  {pedidos.boleta_estado === 5 && "Entregada"}
                                  {pedidos.boleta_estado === 6 && "Anulada"}
                                  {pedidos.boleta_estado === 7 && "Rechazada"}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                <time dateTime={pedidos.fecha}>
                                  {moment(pedidos.fecha).format("DD/MM/YYYY")}
                                  <div className="text-xs">
                                    {moment(pedidos.fecha).format("HH:mm:ss")}
                                  </div>{" "}
                                </time>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      <nav
                        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700">
                            <span className="font-medium">
                              {resultsStart} - {resultsEnd} Resultados de{" "}
                              {pedidos?.length}
                            </span>
                          </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                          <button
                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            onClick={prevPage}
                            disabled={currentPage === 1}
                          >
                            Anterior
                          </button>
                          <button
                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                            onClick={nextPage}
                            disabled={indexOfLastItem >= pedidos?.length}
                          >
                            Siguiente
                          </button>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
