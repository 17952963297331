import { useEffect } from 'react';
import { TablaPedidosState } from '../../context/tablaPedidos/TablaPedidosState';
import TablaPedidos from '../../components/dashboardVendedor/pedidos/tablaPedidos';


export function PedidosVendedor() {
    
    useEffect(() => {
        document.documentElement.className = "h-full bg-gray-100";
        document.body.className = "h-full";
        document.getElementById('root').className = "min-h-full";
    }, []);

  return (
    <>
        <main className="flex-1">
            <div className="py-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Pedidos</h1>
                </div>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    <TablaPedidosState>
                        <TablaPedidos/>
                    </TablaPedidosState>
                </div>
            </div>
        </main>
    </>
  )
}
