export function obtenerRutaImagen(palabrasClave) {
    // Este es tu array de nombres de imágenes.
    const nombresImagenes = ["MEDIDAS BLUSA MUJER MANGA LARGA TW.jpg", "MEDIDAS CAMISA HOMBRE MANGA LARGA TW.jpg",
    "MEDIDAS MICROPOLAR HOMBRE EXPEDITION TW.jpg", "MEDIDAS MICROPOLAR MUJER EXPEDITION TW.jpg", "MEDIDAS MICROPOLAR HOMBRE MANGA LARGA TW.jpg",
    "MEDIDAS MICROPOLAR MUJER MANGA LARGA TW.jpg", "MEDIDAS POLERA HOMBRE MANGA CORTA TW.jpg", "MEDIDAS POLERA HOMBRE MANGA LARGA TW.jpg",
    "MEDIDAS POLERA MUJER MANGA CORTA TW.jpg", "MEDIDAS POLERA MUJER MANGA LARGA TW.jpg", "OM - MEDIDAS SOFTSHELL HOMBRE RAPEL TW.jpg" ];
  
    // Convertimos las palabras clave en un array de palabras individuales.
    const palabras = palabrasClave.split(' ');
  
    // Buscamos en el array de nombres de imágenes y encontramos la que tenga la mayor coincidencia con las palabras clave.
    const resultado = nombresImagenes.reduce((nombreImagenMasCoincidente, nombreImagenActual) => {
      const palabrasNombreImagenActual = nombreImagenActual.split(' ');
  
      // Calculamos el número de coincidencias de palabras clave en el nombre de la imagen actual.
      const numeroCoincidenciasActual = palabrasNombreImagenActual.reduce((numeroCoincidencias, palabra) => {
        return numeroCoincidencias + (palabras.includes(palabra) ? 1 : 0);
      }, 0);
  
      // Si el número de coincidencias de la imagen actual es mayor que el de la imagen más coincidente hasta ahora, actualizamos la imagen más coincidente.
      if (numeroCoincidenciasActual > nombreImagenMasCoincidente.numeroCoincidencias) {
        return { nombre: nombreImagenActual, numeroCoincidencias: numeroCoincidenciasActual };
      } else {
        return nombreImagenMasCoincidente;
      }
    }, { nombre: '', numeroCoincidencias: 0 });
    // Si no se encontró ninguna coincidencia, devolvemos null.
    if (resultado.numeroCoincidencias === 0) {
      return null;
    }
  
    // Asegúrate de que el nombre de la carpeta y la extensión de la imagen sean correctos.
    // Este ejemplo asume que todas las imágenes están en la carpeta 'imagenes'.
    const rutaImagen = require(`./${resultado.nombre}`);
  
    // Devolvemos solo el src de la imagen.
    return rutaImagen;
  }
  