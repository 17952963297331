import CryptoJS from 'crypto-js';

import { REACT_APP_LLAVESECRETA } from './env.js';

// Clave secreta para encriptación/desencriptación
const secretKey = REACT_APP_LLAVESECRETA;

// Función para encriptar los datos
export const encriptarDatos = (llaveLocal, datos) => {
    const datosEncriptados = CryptoJS.AES.encrypt(datos, secretKey).toString();
    localStorage.setItem(llaveLocal, datosEncriptados);
};

// Función para desencriptar los datos
export const desencriptarDatos = (llaveLocal) => {
    const datosEncriptados = localStorage.getItem(llaveLocal);
    if (datosEncriptados) {
        const bytes = CryptoJS.AES.decrypt(datosEncriptados, secretKey);
        const datosDesencriptados = bytes.toString(CryptoJS.enc.Utf8);
        return datosDesencriptados;
    }
    return null;
};
