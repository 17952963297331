import { useEffect } from 'react';
import { TablaBeneficiariosState } from '../../context/tablaBeneficiarios/TablaBeneficiariosState';
import TablaBeneficiarios from '../../components/dashboardAdministrador/beneficiarios/tablaBeneficiarios';

export function InicioAdministracion() {

    useEffect(() => {
      document.documentElement.className = "h-full bg-gray-100";
      document.body.className = "h-full";
      document.getElementById('root').className = "min-h-full";
  }, []);

  return (
    <>
        <main className="flex-1">
            <div className="py-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Beneficiarios</h1>
                </div>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    <TablaBeneficiariosState>
                        <TablaBeneficiarios />
                    </TablaBeneficiariosState>
                </div>
            </div>
        </main>
    </>
  )
}
