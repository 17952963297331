import TablaProductos from "../../components/dashboardVendedor/productos/tablaProductos";
import { TablaProductosState } from "../../context/tablaProducto/TablaProductoState";
import { useEffect } from "react";
import { TablaCatalogosState } from "../../context/tablaCatalogo/TablaCatalogoState";

export function Producto() {
  useEffect(() => {
    document.documentElement.className = "h-full bg-gray-100";
    document.body.className = "h-full";
    document.getElementById("root").className = "min-h-full";
  }, []);

  return (
    <>
      <main className="flex-1">
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Productos</h1>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <TablaProductosState>
              <TablaCatalogosState>
                <TablaProductos />
              </TablaCatalogosState>
            </TablaProductosState>
          </div>
        </div>
      </main>
    </>
  );
}
