import { useContext, useEffect, useState } from "react";
import TablaProductosContext from "../../../context/tablaProducto/TablaProductoContext";
import AgregaProducto from "./agregaProducto";
import EditaProducto from "./editaProducto";
import EliminaProducto from "./eliminaProducto";
import {
  TrashIcon,
  PencilIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { REACT_APP_API } from "../../../service/env";
import TablaCatalogosContext from "../../../context/tablaCatalogo/TablaCatalogoContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TablaProductos() {
  const {
    setOpenAddProducto,
    setOpenUpdateProducto,
    setOpenDeleteProducto,
    resultado,
    getProducto,
    setBusqueda,
  } = useContext(TablaProductosContext);
  const { getCatalogo } = useContext(TablaCatalogosContext);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * 8;
  const indexOfFirstItem = indexOfLastItem - 8;
  const currentItems = resultado?.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const resultsStart = indexOfFirstItem + 1;
  const resultsEnd = Math.min(indexOfLastItem, resultado?.length);

  useEffect(() => {
    getProducto();
    getCatalogo();
  }, []);

  return (
    <>
      <AgregaProducto />
      <EditaProducto />
      <EliminaProducto />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">Listado de productos.</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setOpenAddProducto(true)}
            >
              Agregar
            </button>
          </div>
        </div>
        <form className="mt-4">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-500 focus:border-gray-500 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-gray-500 dark:focus:border-gray-500"
              placeholder="Buscar..."
              required
              onChange={(search) => {
                setBusqueda(search.target.value);
              }}
            />
          </div>
        </form>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="block min-w-full py-2 align-middle">
              <div className="ring-1 ring-black ring-opacity-5 overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Imagen
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Nombre
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Colores
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Descripción
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Tallas
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Medidas
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Precio
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentItems?.map((producto, productoIdx) => (
                      <tr key={producto.id} className="hover:bg-slate-50">
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          )}
                        >
                          <img
                            className="inline-block h-12 w-12 rounded-md"
                            src={
                              typeof producto.imagen == "string"
                                ? REACT_APP_API + "/" + producto.imagen
                                : producto.imagen != null &&
                                  URL.createObjectURL(producto.imagen)
                            }
                            alt=""
                          />
                        </td>
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "text-justify px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {producto.nombre}
                        </td>
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-3 text-justify py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {producto.colores}
                        </td>
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap truncate px-4 py-4 w-8 text-sm font-medium text-gray-500 hidden sm:table-cell"
                          )}
                          style={{ maxWidth: "0" }}
                        >
                          <p
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {producto.descripcion}
                          </p>
                        </td>
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "text-justify px-3 py-4 text-sm text-gray-500"
                          )}
                        >
                          {producto.tallas}
                        </td>
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "text-justify px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {producto.alto}x{producto.ancho}x{producto.largo}
                        </td>
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "text-justify px-3 py-4 text-sm text-gray-500"
                          )}
                        >
                          {parseInt(producto.precio).toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          })}
                        </td>
                        <td
                          className={classNames(
                            productoIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap text-justify px-3 py-4 text-sm text-gray-500"
                          )}
                        >
                          <button
                            type="button"
                            className="px-2 mr-1 inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-400 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 sm:w-auto"
                            onClick={() =>
                              setOpenUpdateProducto(true, producto)
                            }
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            type="button"
                            className="px-2 ml-1 inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                            onClick={() =>
                              setOpenDeleteProducto(true, producto)
                            }
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                    {resultado.length === 0 && (
                      <tr
                        className="border-t border-gray-200 bg-white"
                        style={{ height: 80 }}
                      >
                        <td
                          colSpan={12}
                          className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 text-center"
                        >
                          No se encontraron resultados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {resultado.length > 0 && (
                  <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        <span className="font-medium">
                          {resultsStart} - {resultsEnd} Resultados de{" "}
                          {resultado?.length}
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                      <button
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        Anterior
                      </button>
                      <button
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 bg-cyan-700 hover:bg-gray-50"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= resultado?.length}
                      >
                        Siguiente
                      </button>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
