import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  HomeIcon,
  XMarkIcon,
  ClipboardDocumentListIcon,
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  ShoppingBagIcon,
  Bars3Icon,
  ArrowRightOnRectangleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import SideBarContext from "../../context/sideBar/SideBarContext";
import {
  Catalogo,
  InicioVendedor,
  PedidosVendedor,
  Producto,
} from "../../pages";
import { Login } from "../../pages";
import { ResumenEmpresa } from "../../pages/vendedor/resumenEmpresa";
import { TablaPedidosState } from "../../context/tablaPedidos/TablaPedidosState";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SideBar(props) {
  const { getEmpresa, empresas } = useContext(SideBarContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [click, setClick] = useState(false);
  const ACCESS_TOKEN = "accessToken";

  useEffect(() => {
    getEmpresa();
  }, []);

  const navigation = [
    {
      name: "Inicio",
      href: "/vendedor/",
      icon: HomeIcon,
      current: props.url === "Inicio" ? true : false,
    },
    {
      name: "Catalogos",
      href: "/vendedor/catalogo",
      icon: BuildingStorefrontIcon,
      current: props.url === "Catalogos" ? true : false,
    },
    {
      name: "Productos",
      href: "/vendedor/producto",
      icon: ShoppingBagIcon,
      current: props.url === "Productos" ? true : false,
    },
    {
      name: "Pedidos",
      href: "/vendedor/pedidos",
      icon: ClipboardDocumentListIcon,
      current: props.url === "Pedidos" ? true : false,
    },
    {
      name: "Salir",
      href: "/",
      icon: ArrowRightOnRectangleIcon,
      current: props.url === "Salir" ? true : false,
    },
  ];

  const empresas_navegation = [];

  empresas.map((empresa) => {
    if (empresa.nombre != "PuertoSur") {
      empresas_navegation.push({
        name: empresa.nombre,
        href: `/vendedor/${empresa.nombre}`,
        icon: BuildingOffice2Icon,
        current: props.url === `${empresa.nombre}` ? true : false,
      });
    }
  });
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                    alt="Easywire logo"
                  />
                </div>
                <nav
                  className="mt-5 h-full flex flex-1 flex-col flex-shrink-0 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="space-y-1 px-2">
                    {navigation.map(
                      (item) =>
                        item.name !== "Salir" && (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current && item.name !== "Salir"
                                ? "bg-cyan-800 text-white font-bold"
                                : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                              "group flex items-end px-2 py-2 text-sm leading-6 rounded-md"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        )
                    )}
                  </div>

                  <div className="space-y-1 px-2">
                    <button
                      className={classNames(
                        click && "font-bold",
                        "w-full roup flex items-end px-2 py-2 text-sm leading-6 rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                      )}
                      onClick={() => (click ? setClick(false) : setClick(true))}
                    >
                      <BuildingOffice2Icon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" />
                      Empresas{" "}
                      <ChevronDownIcon
                        className="ml-5 h-5 w-5 flex-shrink-0 text-cyan-200"
                        aria-hidden="true"
                      />
                    </button>
                    {click &&
                      empresas_navegation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-cyan-800 text-white font-bold"
                              : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                            "ml-10 group flex items-end px-2 py-2 text-sm leading-6 rounded-md"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                  </div>

                  <div className="mt-auto space-y-1 px-2">
                    {navigation.map(
                      (item) =>
                        item.name === "Salir" && (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-cyan-800 text-white font-bold"
                                : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                              "group flex items-end px-2 py-2 text-sm leading-6 rounded-md"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        )
                    )}
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto bg-cyan-700 pb-4">
          <div className="flex flex-shrink-0 items-center px-4 bg-gray-200 py-5">
            <img
              className="h-20 w-auto"
              src="https://comercialpuertosur.cl/wp-content/themes/puertosurCL/assets/img/logotipo.png"
              alt="Easywire logo"
            />
          </div>
          <nav
            className="mt-5 flex flex-1 flex-col overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="space-y-1 px-2">
              {navigation.map(
                (item) =>
                  item.name !== "Salir" && (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current && item.name !== "Salir"
                          ? "bg-cyan-800 text-white font-bold"
                          : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                        "group flex items-end px-2 py-2 text-sm leading-6 rounded-md"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  )
              )}
            </div>

            <div className="space-y-1 px-2">
              <button
                className={classNames(
                  click && "font-bold",
                  "w-full roup flex items-end px-2 py-2 text-sm leading-6 rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                )}
                onClick={() => (click ? setClick(false) : setClick(true))}
              >
                <BuildingOffice2Icon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" />
                Empresas{" "}
                <ChevronDownIcon
                  className="ml-20 h-5 w-5 flex-shrink-0 text-cyan-200"
                  aria-hidden="true"
                />
              </button>
              {click &&
                empresas_navegation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-cyan-800 text-white font-bold"
                        : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                      "ml-10 group flex items-end px-2 py-2 text-sm leading-6 rounded-md"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </a>
                ))}
            </div>

            <div className="mt-auto space-y-1 px-2">
              {navigation.map(
                (item) =>
                  item.name === "Salir" && (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-cyan-800 text-white font-bold"
                          : "text-cyan-100 hover:text-white hover:bg-cyan-600",
                        "group flex items-end px-2 py-2 text-sm leading-6 rounded-md"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  )
              )}
            </div>
          </nav>
        </div>
      </div>
      <div className="flex flex-1 flex-col lg:pl-64">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <TablaPedidosState>
          {props.url === "Inicio" && <InicioVendedor />}
          {props.url === "Catalogos" && <Catalogo />}
          {props.url === "Productos" && <Producto />}
          {props.url === "Pedidos" && <PedidosVendedor />}
          {props.url === "Salir" && <Login /> &&
            localStorage.removeItem(ACCESS_TOKEN)}
          {empresas.map((empresa) => {
            if (props.url === empresa.nombre && empresa.nombre !== "Puerto Sur") {
              return <ResumenEmpresa key={empresa.id} empresa={empresa} />;
            }
          })}
        </TablaPedidosState>

      </div>
    </>
  );
}
