import { useContext, useState } from "react";
import { Switch } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TablaPedidosContext from "../../../context/tablaPedidos/TablaPedidoContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function FormEntregarPedido() {
  const [numSeguimiento, setNumSeguimiento] = useState(false);
  const { setOpenNumPedido, actualizarEstado, entregarPedido } =
    useContext(TablaPedidosContext);
  return (
    <Formik
      initialValues={{
        seguimiento: "",
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
      validationSchema={Yup.object({
        seguimiento: Yup.number()
          .min(1, "No puede ser 0")
          .required("Debe ingresar un número de seguimiento")
          .positive("Debe ingresar un número de seguimiento válido")
          .typeError("Debe ser un número"),
      })}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="m-5 sm:flex sm:items-start space-y-8 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:col-span-2 sm:mt-0">
                <p className="text-sm text-gray-600 ">
                  ¿El pedido cuenta con número de seguimiento?
                </p>
                <Switch
                  checked={numSeguimiento}
                  onChange={setNumSeguimiento}
                  onClick={() => {
                    setNumSeguimiento(numSeguimiento);
                  }}
                  className={classNames(
                    numSeguimiento ? "bg-indigo-600" : "bg-gray-200",
                    "mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      numSeguimiento ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                {numSeguimiento && (
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <label
                        htmlFor="seguimiento"
                        className="block text-sm font-medium text-gray-600 sm:mt-px sm:pt-2"
                      >
                        Ingrese el número de seguimiento
                      </label>
                      <Field
                        type="text"
                        name="seguimiento"
                        className={classNames(
                          errors.seguimiento == undefined
                            ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            : touched.seguimiento ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                          "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                        )}
                      />
                      {errors.seguimiento && (
                        <div className="text-red-500 text-sm">
                          {errors.seguimiento}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-4 pt-5 sm:flex sm:flex-row-reverse">
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => setOpenNumPedido(false, {})}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={() => {
                  numSeguimiento === false && (values.seguimiento = "");
                  entregarPedido.boleta_estado = 5;
                  setOpenNumPedido(false, {});
                  actualizarEstado(entregarPedido);
                }}
              >
                Entregar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
