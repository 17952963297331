import { useContext, useEffect, useState } from "react";
import TablaCatalogosContext from "../../../context/tablaCatalogo/TablaCatalogoContext";
import AgregaCatalogo from "./agregaCatalogo";
import AnexaCatalogo from "./anexaCatalogo";
import EditaCatalogo from "./editaCatalogo";
import EliminaCatalogo from "./eliminaCatalogo";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TablaCatalogos() {
  const {
    setOpenAddCatalogo,
    setOpenUpdateCatalogo,
    setOpenDeleteCatalogo,
    catalogos,
    getCatalogo,
  } = useContext(TablaCatalogosContext);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * 8;
  const indexOfFirstItem = indexOfLastItem - 8;
  const currentItems = catalogos?.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const resultsStart = indexOfFirstItem + 1;
  const resultsEnd = Math.min(indexOfLastItem, catalogos?.length);
  useEffect(() => {
    getCatalogo();
  }, []);
  return (
    <>
      <AnexaCatalogo />
      <AgregaCatalogo />
      <EditaCatalogo />
      <EliminaCatalogo />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setOpenAddCatalogo(true)}
            >
              Agregar
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="block min-w-full py-2 align-middle">
              <div className="ring-1 ring-black ring-opacity-5 overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Nombre
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Descripción
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Empresa
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentItems?.map((catalogo, catalogoIdx) => (
                      <tr key={catalogo.id} className="hover:bg-slate-50">
                        <td
                          className={classNames(
                            catalogoIdx !== catalogos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm text-gray-500"
                          )}
                        >
                          {catalogo.nombre}
                        </td>
                        <td
                          id="descripcion"
                          className={classNames(
                            catalogoIdx !== catalogos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {catalogo.descripcion}
                        </td>
                        <td
                          className={classNames(
                            catalogoIdx !== catalogos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                          )}
                        >
                          {catalogo.empresa}
                        </td>
                        <td
                          className={classNames(
                            catalogoIdx !== catalogos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                          )}
                        >
                          <button
                            type="button"
                            className=" m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 sm:w-auto"
                            onClick={() =>
                              setOpenUpdateCatalogo(true, catalogo)
                            }
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            type="button"
                            className="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                            onClick={() => {
                              setOpenDeleteCatalogo(true, catalogo);
                            }}
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <nav
                  className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      <span className="font-medium">
                        {resultsStart} - {resultsEnd} Resultados de{" "}
                        {catalogos?.length}
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-1 justify-between sm:justify-end">
                    <button
                      className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      Anterior
                    </button>
                    <button
                      className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 bg-cyan-700 hover:bg-gray-50"
                      onClick={nextPage}
                      disabled={indexOfLastItem >= catalogos?.length}
                    >
                      Siguiente
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
