import axios from "axios";


export const activaCuentaApi = async (cuenta) => {
    return await axios.post('/cuenta/add',cuenta)
}

export const verificaCuentaApi = async (rut) => {return await axios.get(`/cuenta/valida/${rut}`)}
export const validaCuentaApi = async (cuenta) => {return await axios.post('/cuenta/valida',cuenta)}

