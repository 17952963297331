import axios from "axios";

export const addProductoApi = async (producto) => {
    
    const form = new FormData();

    for (let key in producto){
        form.append(key, producto[key]);
    }

    return await axios.post('producto/add', form, {
        headers : {
            "Content-Type" : "multipart/form-data"
        }
    })
    
}

export const editProductoApi = async (producto) => {
    
    const form = new FormData();

    for (let key in producto){
        form.append(key, producto[key]);
    }

    return await axios.put('/producto/edit/' + producto.id, form, {
        headers : {
            "Content-Type" : "multipart/form-data"
        }
    })
    
}

export const deleteProductoApi = async (id) => {
    return await axios.delete(`/producto/delete/${id}`);
}

export const getProductoApi = async (id) => {
    return await axios.get('/producto');
}

export const getProductosEmpresaApi = async (id) => {
    return await axios.get(`/producto/empresa/${id}`);
}