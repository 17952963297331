import { useContext, useEffect } from "react";
import { ScaleIcon } from "@heroicons/react/24/outline";
import SideBarContext from "../../context/sideBar/SideBarContext";
import TablaPedidos from "../../components/dashboardVendedor/pedidos/tablaPedidos";

export function ResumenEmpresa({ empresa }) {
  const { getEmpresa, empresas, getEstadisticasEmpresas, estadisticas } =
    useContext(SideBarContext);

  useEffect(() => {
    document.documentElement.className = "h-full bg-gray-100";
    document.body.className = "h-full";
    getEmpresa();
    getEstadisticasEmpresas();
  }, []);
  return (
    <>
      <main className="flex-1">
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 capitalize">{empresa.nombre}</h1>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="mt-8">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {empresas?.map(
                    (card) =>
                      card.nombre === empresa.nombre && (
                        <div
                          key={card.id}
                          className="overflow-hidden rounded-lg bg-white shadow"
                        >
                          <div className="p-5">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                <ScaleIcon className="h-6 w-6 text-gray-400" />
                              </div>
                              <div className="ml-5 w-0 flex-1">
                                <dl>
                                  <dt className="truncate text-sm uppercase font-medium text-gray-500">
                                    ingreso mensual total
                                  </dt>
                                  <dd>
                                    <div className="text-lg font-medium text-gray-900">
                                      {estadisticas.length > 0 ? estadisticas?.map((estadistica) =>
                                        estadistica.empresa_nombre ===
                                        card.nombre ? (
                                          <p key={estadistica.nombre}>
                                            {estadistica ? 
                                              parseInt(
                                                estadistica.venta_mensual
                                              ).toLocaleString("es-CL", {
                                                style: "currency",
                                                currency: "CLP",
                                              }) : 0}
                                          </p>
                                        ) : null
                                      ): "$0"}
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <TablaPedidos />
          </div>
        </div>
      </main>
    </>
  );
}
