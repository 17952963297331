import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import { loginUserApi } from '../api/auth.api';
import toast from 'react-hot-toast'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export function Login() {

    useEffect(() => {
        document.documentElement.className = "h-full bg-gray-50";
        document.body.className = "h-full";
        document.getElementById('root').className = "flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8";
    }, []);

    return (
      <>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                className="mx-auto h-20 w-auto"
                alt="logo"
                src="https://comercialpuertosur.cl/wp-content/themes/puertosurCL/assets/img/logotipo.png"     
            />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <Formik
        
        initialValues={{
            rut : '',
            password : ''
        }}

        onSubmit={async (values) => {
            var res = await loginUserApi(values);
            if(res.data.message === "Administracion encontrado." && res.data.data.persona !== null)
            {
                localStorage.setItem("token", res.data.data.token);
                localStorage.setItem("id_empresa", res.data.data.persona.empresa_id);
                localStorage.setItem("rut", res.data.data.persona.rut)
                if(res.data.data.persona.tipo === 0)
                {
                    window.location.href = "/vendedor"
                }
                else
                {
                    window.location.href = "/administracion"
                }
            }
            else
            {
                toast.error(res.data.message)
            }
            
        }}

        validationSchema = {Yup.object({
            rut: Yup.string()
              .required('Ingrese su rut sin puntos y con guión.'),
            password: Yup.string()
              .required('contraseña es requerida.')
          })}
        
        >
            {({errors, touched}) => (
                <Form className="space-y-6" action="#" method="POST">
                    <div>
                        <label htmlFor="rut" className="block text-sm font-medium text-gray-700">
                        RUT
                        </label>
                        <div className="mt-1">
                        <Field
                            name="rut"
                            className={classNames(
                                errors.rut == undefined ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500' : touched.rut ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
                                "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                            )}
                            placeholder="XXXXXXXX-X"
                        />
                        {errors.rut && touched.rut &&
                            <div className="text-red-500 text-sm">{errors.rut}</div>
                        }
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Contraseña
                        </label>
                        <div className="mt-1">
                        <Field
                            name="password"
                            type="password"
                            className={classNames(
                                errors.password == undefined ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500' : touched.password ?'border-red-300 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
                                "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                            )}    
                        />
                        {errors.password && touched.password &&
                            <div className="text-red-500 text-sm">{errors.password}</div>
                        }
                        </div>
                    </div>
                    <div>
                        <button
                        type="submit"
                        className="flex font-bold w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                        Ingresar
                        </button>
                    </div>
                </Form>
            )}
        </Formik>

        </div>
        </div>
      </>
    )
  }
