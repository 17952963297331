import { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import TiendaContext from "../../context/tienda/TiendaContext";
import NavBar from "./navBar";
import { REACT_APP_API } from "../../service/env";
import Carousel from "./carousel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBarIndex({ empresa }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { carrito } = useContext(TiendaContext);
  
  var cantProducts = 0;
  carrito !== null &&
    carrito.map((productos) => {
      cantProducts += parseInt(productos.cantidad);
    });

  let currentLink = ""
  const location = window.location.pathname.split("/")
  const regex = /^[0-9]*$/;
  location.map((link) => {
    if(link !== "producto" && !regex.test(link)){
      currentLink += link + "/"
    }
  })
  
  return (
    <>
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {/* <div className="flow-root">
                    <Link
                      to={'/' + empresa.nombre + "/ingresa"}
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Entrar
                    </Link>
                  </div> */}
                  <div className="flow-root">
                    <Link
                      href={'/' + empresa.nombre + "/activar-cuenta"}
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Activar Cuenta
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {empresa.carruseles !== null && empresa.carruseles.length > 0 ? (
        <div className={`relative`}
          style={{backgroundColor : empresa.color_primario ? empresa.color_primario : '#000'}}>
          <Carousel carruseles={empresa.carruseles} />
          <div
            aria-hidden="true"
            className="absolute inset-0 bg-gray-100 opacity-50"
          />

          <header className="relative z-10">
            <nav aria-label="Top">
              <div style={{backgroundColor : empresa.color_primario ? empresa.color_primario : '#000'}}>
                <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center space-x-6">
                    {/* <Link
                      to={'/' + empresa.nombre +  "/ingresar"}
                      className="text-sm font-medium text-white hover:font-bold"
                    >
                      Entrar
                    </Link> */}
                    <Link
                      to={'/' + empresa.nombre + "/activar-cuenta"}
                      className="text-sm font-medium text-white hover:font-bold"
                    >
                      Activar Cuenta
                    </Link>
                  </div>
                </div>
              </div>

              <div className="bg-white bg-opacity-10 backdrop-blur-md backdrop-filter">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div>
                    <div className="flex h-16 items-center justify-between">
                      <div className="hidden lg:flex lg:flex-1 lg:items-center">
                        <a href={'/' + empresa.nombre}>
                          <span className="sr-only">{empresa.nombre}</span>
                          <img
                            className="h-8 w-auto"
                            src={REACT_APP_API + '/' + empresa.imagen_logo}
                            alt=""
                          />
                        </a>
                      </div>

                      <div className="flex flex-1 items-center lg:hidden">
                        <a
                          href={'/' + empresa.nombre + "/buscar"}
                          className="ml-2 p-2 text-white"
                        >
                          <span className="sr-only">Buscar</span>
                          <MagnifyingGlassIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </a>
                      </div>

                      <a href={'/' + empresa.nombre} className="lg:hidden">
                        <span className="sr-only">{empresa.nombre}</span>
                        <img
                          className="h-8 w-auto"
                          src={REACT_APP_API + '/' + empresa.imagen_logo}
                          alt=""
                        />
                      </a>

                      <div className="flex flex-1 items-center justify-end">
                        <a
                          href={'/' + empresa.nombre + "/buscar"}
                          className="hidden text-sm font-medium text-white lg:block"
                        >
                          Buscar
                        </a>
                        <div className="flex items-center lg:ml-8">
                          <div className="ml-4 flow-root lg:ml-8">
                            <a
                              href={'/' + empresa.nombre + "/carrito"}
                              className="group -m-2 flex items-center p-2"
                            >
                              <ShoppingBagIcon
                                className="h-6 w-6 flex-shrink-0 text-white"
                                aria-hidden="true"
                              />
                              <span className="ml-2 text-sm font-medium text-white">
                                {cantProducts}
                              </span>
                              <span className="sr-only">
                                articulos en el carrito, ver carrito
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
          <div className="relative mx-auto flex max-w-3xl flex-col items-center py-32 px-6 text-center sm:py-64 lg:px-0"></div>
        </div>
      ) : (
        <NavBar empresa={empresa} />
      )}
    </>
  );
}
