import React, { useEffect, useState } from 'react';
import { useSprings, animated, config } from 'react-spring';
import { REACT_APP_API } from '../../service/env';

const Carousel = ({ carruseles }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = carruseles;
  const springs = useSprings(
    slides.length,
    slides.map((item, index) => ({
      opacity: index === currentSlide ? 1 : 0,
      transform: `translate3d(${index === currentSlide ? 0 : 100}%,0,0)`,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      willChange: 'transform, opacity',
      config: config.slow,
    }))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((current) => (current + 1) % slides.length);
    }, 3000); // Cambia cada 3 segundos

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide absolute inset-0 overflow-hidden">
      <div className="carousel-inner absolute inset-0 overflow-hidden">
        {springs.map((props, index) => (
          <animated.div className="carousel-item block h-full w-full" style={props}>
            <img src={`${REACT_APP_API}/${slides[index]}`} className="block h-full w-full object-cover object-center" alt="carrucel compañia" />
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;