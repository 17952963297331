import axios from "axios";

export const addCatalogoApi = async (catalogo) => {

    return await axios.post('/api/v1/add-catalogo', catalogo)
    
}

export const boletaIDApi = async (id) => { return await axios.get('/venta/' + id) }

export const generaBoletaApi = async (data) => { return await axios.post('/venta/add', data) }
export const validaBoletaApi = async (token_ws) => { return await axios.get(`/venta/valida/${token_ws}`) }

export const getCatalogoEmpresaApi = async (id) => {return await axios.get(`/catalogo/empresa/${id}`)}
export const getProductoCatalogoApi = async (id) => {return await axios.get(`/producto/catalogo/${id}`)}
export const getDetalleProductoApi = async (id) => {return await axios.get(`/producto/${id}`)}