import { useReducer } from "react";
import TiendaReducer from "./TiendaReducer.js";
import TiendaContext from "./TiendaContext.js";
import {
  SET_CARRITO,
  ADD_LOGO,
  SET_CATALOGO,
  SET_PRODUCTO,
  SET_PRODUCTO_DETALLE,
  DELETE_PROD_CARRITO,
  UPDATE_CANTIDAD
} from "../types.js";
import {
  generaBoletaApi,
  getCatalogoEmpresaApi,
  getDetalleProductoApi,
  getProductoCatalogoApi,
} from "../../api/tienda.api.js";
import { getProductosEmpresaApi } from "../../api/producto.api.js";
import { verificaCuentaApi, validaCuentaApi } from "../../api/cuenta.api.js";
import { getBeneficiarioRutApi } from "../../api/beneficiarios.api.js";

export const TiendaState = (props) => {
  const initialTienda = {
    carrito:
      !localStorage.getItem("carrito")
        ? []
        : JSON.parse(localStorage.getItem("carrito")),
    catalogos: [],
    catalogo: {},
    productos: [],
    producto: {},
    empresa : {},
  };

  const [tienda, dispatch] = useReducer(TiendaReducer, initialTienda);

  const setCarrito = async (producto) => {
    try {
      await dispatch({
        type: SET_CARRITO,
        payload: producto,
      });
      return true;
    } catch (e) {
      return e;
    }
  };

  const DeleteProdCarrito = async (producto) => {
    try {
      await dispatch({
        type: DELETE_PROD_CARRITO,
        payload: producto,
      });
      return true
    } catch (e) {
      return e;
    }
  };

  const getCatalogoEmpresa = async (id) => {
    const query = await getCatalogoEmpresaApi(id);

    if (query.data.message === 'Catalogos traidos exitosamente.') {
      dispatch({
        type: SET_CATALOGO,
        payload: query.data.data,
      });

      return true;
    } else {
      return false;
    }
  };

  const getProductoCatalogo = async (id) => {
    const query = await getProductoCatalogoApi(id);

    if (query.data.message === "Produco obtenido.") {
      dispatch({
        type: SET_PRODUCTO,
        payload: query.data.data,
      });

      return true;
    } else {
      return false;
    }
  };

  const getDetalleProductoEmpresa = async (id) => {
    const query = await getDetalleProductoApi(id);

    if (query.data.message === "Produco obtenido.") {
      dispatch({
        type: SET_PRODUCTO_DETALLE,
        payload: query.data.data[0]
      });

      return true;
    } else {
      return false;
    }
  };

  const addBoleta = async (data) => {
    const query = await generaBoletaApi(data);
    if (query.data.message === "Venta agregada exitosamente.") {
      return query.data.data;
    } else {
      return query.data.data;
    }
  };

  const UpdateCarrito = (cantidad, producto) => {
    dispatch({
      type: UPDATE_CANTIDAD,
      payload: {cantidad, producto}
    })
  }

  const setOpenAddLogo = (bool, producto) => {
    dispatch({
      type: ADD_LOGO,
      payload : {bool, producto}
    })
  }

  const getProductosEmpresa = async (id) => {
    const productos = await getProductosEmpresaApi(id);
    if(productos.data.message === "Produco obtenido.")
    {
        dispatch({
            type : SET_PRODUCTO,
            payload : productos.data.data
        })
    }
    else
    {
        return false
    }
  }

  const verificaCuenta = async (rut) => {
    const query = await verificaCuentaApi(rut);
    if(query.data.message === "Cuenta encontrada."){
      return true
    }
    else{
      return false
    }
  }

  const validaCuenta = async (cuenta) => {
    const query = await validaCuentaApi(cuenta);
    if(query.data.message === "Validado."){
      return {
        res : true,
        persona : query.data.data.persona
      }
    }
    else{
      return {
        res : false,
        message : query.data.message
      }
    }
  }

  const verificaBeneficiario = async (rut) => {
    const query = await getBeneficiarioRutApi(rut);
    if(query.data.message === "Beneficiario obtenido."){
      return true
    }
    else{
      return false
    }
  }

  const setEmpresa = async (empresa) => {
    dispatch({
      type : 'SET_EMPRESA',
      payload : empresa
    })
  }

    return (
    <TiendaContext.Provider
      value={{
        carrito: tienda.carrito,
        catalogos: tienda.catalogos,
        catalogo: tienda.catalogo,
        productos: tienda.productos,
        producto: tienda.producto,
        empresa : tienda.empresa,
        setCarrito,
        getCatalogoEmpresa,
        getProductoCatalogo,
        getDetalleProductoEmpresa,
        DeleteProdCarrito,
        UpdateCarrito,
        setOpenAddLogo,
        getProductosEmpresa,
        verificaCuenta,
        validaCuenta,
        verificaBeneficiario,
        setEmpresa,
        addBoleta
      }}
    >
      {props.children}
    </TiendaContext.Provider>
  );
};
