import { useContext, useEffect, useState } from "react";
import TablaBeneficiariosContext from "../../../context/tablaBeneficiarios/TablaBeneficiariosContext";
import AgregarBeneficiario from "./agregarBeneficiario";
import ImportarBeneficiario from "./importarBeneficiarios";
import ModalDetalleBeneficiario from "./detalleBeneficiario";
import EditaBeneficiario from "./editaBeneficiario";
import {
  TrashIcon,
  PencilIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import EliminaBeneficiario from "./eliminaBeneficiario";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TablaBeneficiarios() {
  const {
    setOpenEditaBeneficiario,
    setOpenAgregarBeneficiario,
    setOpenImportarBeneficiarios,
    getBeneficiarios,
    setOpenDetalleBeneficiario,
    setOpenDeleteBeneficiario,
    resultado,
    setBusqueda,
  } = useContext(TablaBeneficiariosContext);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * 8;
  const indexOfFirstItem = indexOfLastItem - 8;
  const currentItems = resultado?.slice(indexOfFirstItem, indexOfLastItem);
  const resultsStart = indexOfFirstItem + 1;
  const resultsEnd = Math.min(indexOfLastItem, resultado?.length);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const user = localStorage.getItem("id_empresa");

  useEffect(() => {
    const id_empresa = localStorage.getItem("id_empresa");
    getBeneficiarios(id_empresa);
  }, []);
  return (
    <>
      <ImportarBeneficiario />
      <AgregarBeneficiario id_empresa={user !== null && user.id_empresa} />
      <ModalDetalleBeneficiario />
      <EditaBeneficiario />
      <EliminaBeneficiario />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-700">
              Lista de beneficiarios con datos escenciales pedidos por la
              empresa.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className=" mx-5 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setOpenImportarBeneficiarios(true)}
            >
              Importar
            </button>
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setOpenAgregarBeneficiario(true)}
            >
              Añadir
            </button>
          </div>
        </div>
        <form className="mt-4">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-500 focus:border-gray-500 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray dark:focus:ring-gray-500 dark:focus:border-gray-500"
              placeholder="Buscar..."
              required
              onChange={(search) => {
                setBusqueda(search.target.value);
              }}
            />
          </div>
        </form>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="ring-1 ring-black ring-opacity-5 overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        RUT
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Nombre
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Relacion Laboral
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Deuda
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Detalle Beneficiario
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentItems.map((person, personIdx) => (
                      <tr key={person.rut} className="hover:bg-slate-50">
                        <td
                          className={classNames(
                            personIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm font-medium text-gray-500"
                          )}
                        >
                          {person.rut}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm font-medium text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {person.nombres} {person.apellido_paterno} {person.apellido_materno}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm font-medium text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {person.relacion_laboral === null
                            ? "No hay datos"
                            : person.relacion_laboral}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm font-medium text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {person.deuda === 0 ? "Sin deuda" : "Presenta deuda"}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-3 py-4 text-sm text-gray-500"
                          )}
                        >
                          <button
                            type="button"
                            className="px-2 mr-1 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-400 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 sm:w-auto"
                            onClick={() =>
                              setOpenDetalleBeneficiario(true, person)
                            }
                          >
                            <MagnifyingGlassIcon
                              className="h-5 w-5 flex-shrink-0"
                              aria-hidden="true"
                            />
                          </button>
                        </td>
                        <td
                          className={classNames(
                            personIdx !== resultado.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          )}
                        >
                          <button
                            type="button"
                            className="px-2 m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-400 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 sm:w-auto"
                            onClick={() => {
                              person.id_empresa =
                                user !== null && user.id_empresa;
                              setOpenEditaBeneficiario(true, person);
                            }}
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            type="button"
                            className="px-2 ml-1 inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                            onClick={() => {
                              setOpenDeleteBeneficiario(true, person);
                            }}
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                    {resultado.length === 0 && (
                      <tr
                        className="border-t border-gray-200 bg-white"
                        style={{ height: 80 }}
                      >
                        <td
                          colSpan={6}
                          className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 text-center"
                        >
                          No se encontraron resultados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {resultado.length > 0 && (
                  <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        <span className="font-medium">
                          {resultsStart} - {resultsEnd} Resultados de{" "}
                          {resultado?.length}
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                      <button
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        Anterior
                      </button>
                      <button
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 bg-cyan-700 hover:bg-gray-50"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= resultado?.length}
                      >
                        Siguiente
                      </button>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
