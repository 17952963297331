import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useContext } from "react";
import TablaBeneficiariosContext from "../../../context/tablaBeneficiarios/TablaBeneficiariosContext";
import ValidarRut from "../../ValidarRut";
import toast from "react-hot-toast";
import formatearRut from "../../FormatearRut";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FormAgregarBeneficiario(props) {
  const { setOpenAgregarBeneficiario, addBeneficiario } = useContext(
    TablaBeneficiariosContext
  );

  return (
    <Formik
      initialValues={{
        rut: "",
        nombres: "",
        apellido_paterno: "",
        apellido_materno:"",
        deuda: 0,
      }}
      onSubmit={async (values) => {
        values = { ...values, id_empresa: localStorage.getItem('id_empresa'), rut_formateado: "" };
        values.rut_formateado = formatearRut(values.rut)
        if(ValidarRut(values.rut)){
            const res = await addBeneficiario(values);
            if (res === true) {
              setOpenAgregarBeneficiario(false);
            } else {
              setOpenAgregarBeneficiario(false);
            }
        }
        else{
            toast.error("Rut inválido")
        }
      }}
      validationSchema={Yup.object({
        rut: Yup.string()
          .required("Ingrese su rut sin puntos y con guión")
          .min(9, "rut inválido."),
        nombres: Yup.string().required("Nombre es requerido."),
        apellido_materno: Yup.string().required("Apellido es requerido"),
        apellido_paterno: Yup.string().required("Apellido es requerido"),
        fecha_ingreso: Yup.string().nullable(),
        fecha_nacimiento: Yup.string().nullable(),
        relacion_laboral: Yup.string().nullable(),
        direccion: Yup.string().nullable(),
        funcion: Yup.string().nullable()
      })}
    >
      {({ errors, touched }) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="sm:flex sm:items-start space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Añadir Beneficiario
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Rellene los campos para agrega un beneficiario.
                </p>
              </div>

              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="rut"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Rut
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="rut"
                      length="10"
                      placeholder="XXXXXXXX-X"
                      className={classNames(
                        errors.rut === undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.rut ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.rut && touched.rut && (
                      <div className="text-red-500 text-sm">{errors.rut}</div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="nombres"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Nombres
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="nombres"
                      className={classNames(
                        errors.nombres === undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.nombres ? "border-red-300 focus:border-red-500 focus:ring-red-500": "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.nombres && touched.nombres && (
                      <div className="text-red-500 text-sm">
                        {errors.nombres}
                      </div>
                    )}
                    <p className="mt-2 text-sm text-gray-500"></p>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="apellido_paterno"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Apellido Paterno
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="apellido_paterno"
                      className={classNames(
                        errors.apellido_paterno == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.apellido_paterno ? "border-red-300 focus:border-red-500 focus:ring-red-500": "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.apellido_paterno && touched.apellido_paterno && (
                      <div className="text-red-500 text-sm">
                        {errors.apellido_paterno}
                      </div>
                    )}
                    <p className="mt-2 text-sm text-gray-500"></p>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="apellido_materno"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Apellido Materno
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="apellido_materno"
                      className={classNames(
                        errors.apellido_materno == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.apellido_materno ? "border-red-300 focus:border-red-500 focus:ring-red-500": "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.apellido_materno && touched.apellido_materno && (
                      <div className="text-red-500 text-sm">
                        {errors.apellido_materno}
                      </div>
                    )}
                    <p className="mt-2 text-sm text-gray-500"></p>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="fecha_ingreso"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Fecha Ingreso
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="date"
                      name="fecha_ingreso"
                      className={classNames(
                        errors.fecha_ingreso == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.fecha_ingreso && (
                      <div className="text-red-500 text-sm">
                        {errors.fecha_ingreso}
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="fecha_nacimiento"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Fecha Nacimiento
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="date"
                      name="fecha_nacimiento"
                      className={classNames(
                        errors.fecha_nacimiento == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.fecha_nacimiento && (
                      <div className="text-red-500 text-sm">
                        {errors.fecha_nacimiento}
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="relacion_laboral"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Relación Laboral
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="relacion_laboral"
                      className={classNames(
                        errors.relacion_laboral == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.relacion_laboral && (
                      <div className="text-red-500 text-sm">
                        {errors.relacion_laboral}
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="direccion"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Dirección
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="direccion"
                      className={classNames(
                        errors.direccion == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.direccion && (
                      <div className="text-red-500 text-sm">
                        {errors.direccion}
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="funcion"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Función
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="funcion"
                      className={classNames(
                        errors.funcion == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.funcion && (
                      <div className="text-red-500 text-sm">
                        {errors.funcion}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-4 pt-5 sm:flex sm:flex-row-reverse">
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => setOpenAgregarBeneficiario(false)}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Guardar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
