import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { activaCuentaApi } from "../api/cuenta.api";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import ValidarRut from "../components/ValidarRut";
import { desencriptarDatos } from "../service/AES";
import { REACT_APP_API } from "../service/env";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function ActivarCuenta() {
  let currentLink = "";
  const location = window.location.pathname.split("/");
  const empresa = JSON.parse(desencriptarDatos("empresa"));
  location.pop();
  location.map((link) => {
    currentLink += link + "/";
  });

  useEffect(() => {
    document.documentElement.className = "h-full bg-gray-50";
    document.body.className = "h-full";
    document.getElementById("root").className =
      "flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8";
  }, []);

  return (
    <>
      <div className="flex absolute top-0 left-0 p-5">
        <a
          href={"/" + empresa.nombre}
          className="text-sm"
          style={{
            color: empresa.color_primario ? empresa.color_primario : "#000",
          }}
        >
          <span aria-hidden="true" className="p-2">
            {" "}
            &larr;
          </span>
          Inicio
        </a>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-20 w-auto flex justify-center"
          alt="logo"
          src={REACT_APP_API + "/" + empresa.imagen_logo}
        />
        <p className="mt-8 font-semibold text-center ">Activar Cuenta</p>
        <p className="text-sm font-thin text-center text-gray-500">
          Rellene los campos para activar su cuenta
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            initialValues={{
              rut: "",
              password: "",
              repeatpass: "",
              correo: "",empresa
            }}
            onSubmit={async (values) => {
              if (ValidarRut(values.rut)) {
                const res = await activaCuentaApi(values);
                if (res.data.data.creado) {
                  toast.success("Se ha creado la su cuenta correctamente");
                  setTimeout(() => (window.location.href = "/" + empresa.nombre), "1000");
                } else {
                  toast.error(res.data.message);
                }
              }
            }}
            validationSchema={Yup.object({
              rut: Yup.string()
                .required("Ingrese su rut sin puntos y con guión")
                .matches(/^[0-9]+-[0-9kK]{1}$/, "rut inválido.")
                .length(10, "rut inválido."),
              password: Yup.string().required("Contraseña es requerida."),
              repeatpass: Yup.string()
                .required("Repetir la contraseña es requerida.")
                .oneOf(
                  [Yup.ref("password"), null],
                  "Las contraseñas no coinciden"
                ),
              correo: Yup.string()
                .required("Correo es requerido.")
                .matches(
                  /^[a-zA-Z0-9_.]+@[a-zA-Z0]+\.[a-zA-Z0]+$/,
                  "Correo inválido."
                ),
            })}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="space-y-6" action="#" method="POST">
                <div>
                  <label
                    htmlFor="rut"
                    className="block text-sm font-medium text-gray-700"
                  >
                    RUT
                  </label>
                  <div className="mt-1">
                    <Field
                      name="rut"
                      className={classNames(
                        errors.rut == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.rut
                          ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                      placeholder="XXXXXXXX-X"
                    />
                    {errors.rut && touched.rut && (
                      <div className="text-red-500 text-sm">{errors.rut}</div>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contraseña
                  </label>
                  <div className="mt-1">
                    <Field
                      name="password"
                      type="password"
                      className={classNames(
                        errors.password == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.password
                          ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.password && touched.password && (
                      <div className="text-red-500 text-sm">
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="repeatpass"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Repetir Contraseña
                  </label>
                  <div className="mt-1">
                    <Field
                      name="repeatpass"
                      type="password"
                      className={classNames(
                        errors.repeatpass == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.repeatpass
                          ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.repeatpass && touched.repeatpass && (
                      <div className="text-red-500 text-sm">
                        {errors.repeatpass}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="correo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Correo Electrónico
                  </label>
                  <div className="mt-1">
                    <Field
                      name="correo"
                      type="correo"
                      className={classNames(
                        errors.correo == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.correo
                          ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.correo && touched.correo && (
                      <div className="text-red-500 text-sm">
                        {errors.correo}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-1 flex space-x-5 text-sm font-medium text-gray-600">
                  <Link
                    to={currentLink + "recuperar-contrasena"}
                    className="hover:underline decoration-1"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                  <Link
                    to={currentLink + "ingresar"}
                    className="hover:underline decoration-1"
                  >
                    Ingresar cuenta
                  </Link>
                </div>
                <div>
                  <button
                    type="submit"
                    className={classNames( isSubmitting ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500' : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500', 'flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-bold text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2')}
                    disabled={isSubmitting}
                  >
                    Aceptar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
