import {
  ADD_LOGO,
  DELETE_PROD_CARRITO,
  SET_CARRITO,
  SET_CARRITO_INICIAL,
  SET_CATALOGO,
  SET_EMPRESA,
  SET_OPEN_ADDPRODUCTO,
  SET_PRODUCTO,
  SET_PRODUCTO_DETALLE,
  UPDATE_CANTIDAD,
} from "../types";

const TiendaReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_CARRITO_INICIAL:
      return {
        ...state,
        carrito: payload,
      };

    case SET_EMPRESA:
      return {
        ...state,
        empresa: payload,
      };

    case SET_OPEN_ADDPRODUCTO:
      return {
        ...state,
        estadoAddProducto: payload,
      };

    case SET_CARRITO:
      var bandera = false;
      var carrito = state.carrito.map((producto) => {
        if (
          producto.id === payload.id &&
          producto.color === payload.color &&
          producto.talla === payload.talla &&
          producto.logo === payload.logo
        ) {
          bandera = true;
          return {
            ...producto,
            cantidad: producto.cantidad + 1,
          };
        } else {
          return producto;
        }
      });
      localStorage.setItem("carrito", bandera ? JSON.stringify(carrito) : JSON.stringify([...carrito, payload]));

      return {
        ...state,
        carrito: bandera ? carrito : [...carrito, payload],
      };

    case DELETE_PROD_CARRITO:
      var carrito = state.carrito.filter(
        (producto, index) => !(index === payload)
      );
      localStorage.setItem("carrito", JSON.stringify(carrito));

      return {
        ...state,
        carrito,
      };

    case SET_CATALOGO:
      return {
        ...state,
        catalogos: payload,
      };

    case SET_PRODUCTO:
      return {
        ...state,
        productos: payload,
      };

    case SET_PRODUCTO_DETALLE:
      return {
        ...state,
        producto: payload,
      };

    case UPDATE_CANTIDAD:
      var product_update = state.carrito.filter(producto => producto === payload.producto ? producto.cantidad = payload.cantidad : payload)
      localStorage.setItem("carrito", JSON.stringify(product_update));
      return {
        ...state,
        carrito: product_update
      }
    
    case ADD_LOGO:
      return {
        ...state,
        producto: payload.producto
      }
    default:
      return state;
  }
};

export default TiendaReducer;
