import { XMarkIcon as XMarkIconMini } from "@heroicons/react/20/solid";
import { useContext} from "react";
import toast from "react-hot-toast";
import NavBar from "../../components/tienda/navBar";
import TiendaContext from "../../context/tienda/TiendaContext";
import { REACT_APP_API } from "../../service/env";
import { DatosCarrito } from "../../components/tienda/datosCarrito";

export function CarritoComercio({ empresa }) {
  var precioCarrito = 0;
  var cantProducts = 0;
  const elements = [];

  const { DeleteProdCarrito, carrito, UpdateCarrito } =
    useContext(TiendaContext);

  carrito !== null &&
    carrito.map((productos) => {
      precioCarrito += productos.precio * productos.cantidad;
      cantProducts += parseInt(productos.cantidad);
    });

  for (let i = 0; i < empresa.maximo_cantidad; i++) {
    elements.push(
      <option key={i} value={i + 1}>
        {i + 1}
      </option>
    );
  }
  return (
    <div className="bg-white">
      <NavBar empresa={empresa} />
      <main className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Carrito de compras
        </h1>

        <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">
              Productos del carrito
            </h2>

            <ul
              role="list"
              className="divide-y divide-gray-200 border-t border-b border-gray-200"
            >
              {carrito !== null ? (
                carrito.map((product, productIdx) => (
                  <li key={productIdx} className="flex py-6 sm:py-10">
                    <div className="flex-shrink-0">
                      <img
                        alt="producto imagen"
                        src={REACT_APP_API + "/" + product.imageSrc}
                        className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                      />
                    </div>

                    <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                      <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                        <div>
                          <div className="flex justify-between">
                            <h3 className="text-sm">
                              <a className="font-medium text-gray-700 hover:text-gray-800">
                                {product.nombre}
                              </a>
                            </h3>
                          </div>
                          <div className="mt-1 flex text-sm text-center">
                            <p className="text-gray-500">{product.color}</p>
                            <div className="ml-4 border-l border-gray-200 pl-4 flex flex-1">
                              {product.talla && (
                                <p className="text-gray-500">
                                  {product.talla}
                                </p>
                              )}
                            </div>
                            <div className="ml-4 border-l border-gray-200 pl-4">
                              {product.talla && (
                                <p className="text-gray-500">
                                 {product.logo ? "CON LOGO" : "SIN LOGO"}
                                </p>
                              )}
                            </div>
                          </div>
                          <p className="mt-1 text-sm font-medium text-gray-900">
                            {product.precio?.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            })}
                          </p>
                        </div>

                        <div className="place-self-center mt-4 sm:mt-0 sm:pr-9">
                          <label
                            htmlFor={`quantity-${productIdx}`}
                            className="sr-only"
                          >
                            Cantidad, {product.nombre}
                          </label>
                          <select
                            id={`quantity-${productIdx}`}
                            name={`quantity-${productIdx}`}
                            value={product.cantidad}
                            className="max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => {
                              UpdateCarrito(e.target.value, product);
                            }}
                          >
                            {empresa.maximo ? (
                              <>{elements}</>
                            ) : (
                              <>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                              </>
                            )}
                          </select>

                          <div className="absolute top-0 right-0">
                            <button
                              type="button"
                              className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                              onClick={async () => {
                                await DeleteProdCarrito(productIdx);
                                toast.success("se eliminó " + product.nombre);
                              }}
                            >
                              <span className="sr-only">Eliminar</span>
                              <XMarkIconMini
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <h1 className="font-bold tracking-tight text-gray-600 text-center">
                  No tiene ningún producto
                </h1>
              )}
            </ul>
          </section>

          <section
            aria-labelledby="summary-heading"
            className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
          >
            <h2
              id="summary-heading"
              className="text-lg font-medium text-gray-900"
            >
              Detalle
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="flex items-center justify-between">
                <dt className="text-sm text-gray-600">Cantidad de productos</dt>
                <dd className="text-sm font-medium text-gray-900">
                  {carrito === null ? 0 : cantProducts}
                </dd>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="text-base font-bold text-gray-900">Total</dt>
                <dd className="text-base font-bold text-gray-900">
                  {carrito === null
                    ? 0
                    : precioCarrito.toLocaleString("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      })}
                </dd>
              </div>
              <DatosCarrito empresa={empresa} />
            </dl>
          </section>
        </div>
      </main>
    </div>
  );
}
