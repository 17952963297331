import { useEffect, useState } from "react";
import { desencriptarDatos } from "../../service/AES";
import { REACT_APP_API } from "../../service/env"
import { validaBoletaApi } from "../../api/tienda.api";

export default function ValidaOrden() {

    const empresa = JSON.parse(desencriptarDatos("empresa"));

    const [pago, setPago] = useState(null)

    useEffect(() => {
        
        async function validaBoleta() {
            const url = window.location.href.split("=")
            const response = await validaBoletaApi(url[1])
            setPago(response.data.data)
            pago.response.status === "AUTHORIZED" ? localStorage.removeItem("carrito") : console.log("error")
        }
    
        validaBoleta()

    }, [])
    return (
      <>
        <main className="relative lg:min-h-full">
            <div className="h-80 bg-gray-100 overflow-hidden lg:fixed lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12 flex justify-center items-center">
                <img
                    className="object-contain h-full w-full md:w-1/2"
                    src={REACT_APP_API + '/' + empresa.imagen_logo}
                    alt="logo"
                />
            </div>

            {pago !== null ? (
                <div className="shadow-xl">
                    <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
                        <div className="lg:col-start-2">
                            { pago.response.status === "AUTHORIZED" ? (
                                <div>
                                    <h1 className="text-sm font-medium text-indigo-600">Pago Aprobado</h1>
                                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Gracias por tu compra!</p>
                                    <p className="mt-2 text-base text-gray-500">
                                    Estamos trabajando en procesar tu pedido. Por favor, ten paciencia y te enviaremos la confirmación muy pronto.
                                    </p>
                                </div>
                            ) : (
                                <div>
                                    <h1 className="text-sm font-medium text-indigo-600">Pago Rechazado</h1>
                                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Hubo un problema en el pago.</p>
                                    <p className="mt-2 text-base text-gray-500">
                                        Hubo un error en el proceso de pago, verifica el saldo o comunicate con tu banco.
                                    </p>
                                </div>
                            )}
            
                            <div
                            role="list"
                            className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
                            >
                                {pago.boleta.productos.map((product, index) => (
                                    <li key={index} className="flex space-x-6 py-6">
                                        <img
                                            src={REACT_APP_API + '/' + product.imagen}
                                            alt='logo empresa'
                                            className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                                        />
                                        <div className="flex-auto space-y-1">
                                            <h3 className="text-gray-900">
                                            <a href={window.location.href.split('valida')[0] + 'producto/' + product.id_producto}>{product.nombre}</a>
                                            </h3>
                                            <p>Color : {product.color}</p>
                                            <p>Talla : {product.talla}</p>
                                            <p>Cantidad : {product.cantidad}</p>
                                            {product.logo === 1 ? (
                                                <p>Logo : Sí</p>
                                            ) : (
                                                <p>Logo : No</p>
                                            )}
                                        </div>
                                        <p className="flex-none font-medium text-gray-900"> {parseInt(product.precio * product.cantidad).toLocaleString("es-CL", {
                                            style: "currency",
                                            currency: "CLP",
                                        })} </p>
                                    </li>
                                ))}
                            </div>
            
                            <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
            
                                <div className="flex items-center justify-between pt-6 text-gray-900">
                                    <dt className="text-base">Total</dt>
                                    <dd className="text-base">{parseInt(pago.boleta.total).toLocaleString("es-CL", {
                                        style: "currency",
                                        currency: "CLP",
                                    })}</dd>
                                </div>
                            </dl>
            
                            <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                            <div className="border-r-2 border-gray-300 ">
                                <dt className="font-medium text-gray-900">Dirección de Despacho</dt>
                                <dd className="mt-2">
                                <address className="not-italic">
                                    <span className="block">{pago.boleta.despacho}</span>
                                </address>
                                </dd>
                            </div>
                            <div className="">
                                <dt className="font-medium text-gray-900">Información de Pago</dt>
                                <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                                <div className="flex-none">
                                    <svg aria-hidden="true" width={36} height={24} viewBox="0 0 36 24" className="h-6 w-auto">
                                    <rect width={36} height={24} rx={4} fill="#224DBA" />
                                    <path
                                        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                        fill="#fff"
                                    />
                                    </svg>
                                    <p className="sr-only">Visa</p>
                                </div>
                                <div className="flex-auto">
                                    <p className="text-gray-900">**** {pago.response.card_detail.card_number}</p>
                                </div>
                                </dd>
                            </div>
                            </dl>
            
                            <div className="mt-16 border-t border-gray-200 py-6 text-right">
                            <a href={window.location.href.split('valida')[0]} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                Continuar Comprando
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="shadow-2xl h-80 overflow-hidden lg:fixed lg:h-full lg:w-1/2 lg:left-auto lg:right-0 lg:pr-4 xl:pr-12 flex justify-center items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-full w-full md:w-1/2 text-[#232ACA]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.789 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
        </main>
      </>
    )
  }
  