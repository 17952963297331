import { SET_CATALOGO, SET_OPEN_ADDCATALOGO, SET_CAT_PROD, SET_OPEN_UPDATECATALOGO, SET_OPEN_DELETECATALOGO, DELETE_CATALOGO, UPDATE_CATALOGO, SET_OPEN_ANEXACATALOGO} from "../types";

const TablaCatalogoReducer = (state, action) => {
    const {payload, type} = action

    switch(type){

            case SET_OPEN_ANEXACATALOGO:
                return {
                    ...state,
                    estadoAnexaCatalogo : payload.bool,
                    anexaCatalogo : payload.id == undefined ? 0 : payload
                }

            case SET_CAT_PROD:
                return {
                    ...state,
                    catProd : payload
                }
            case SET_OPEN_ADDCATALOGO:
                return {
                    ...state,
                    estadoAddCatalogo : payload
                }
            case SET_OPEN_UPDATECATALOGO:
                return {
                    ...state,
                    estadoUpdateCatalogo : payload.bool,
                    editaCatalogo : payload.id == undefined ? {} : payload.id
                }
            case SET_OPEN_DELETECATALOGO:
                return {
                    ...state,
                    estadoDeleteCatalogo : payload.bool,
                    eliminaCatalogo : payload.id == undefined ? {} : payload.id
                }
            case SET_CATALOGO:
                return {
                    ...state,
                    catalogos : payload
                };
            case UPDATE_CATALOGO:
                return {
                    ...state,
                    catalogos : state.catalogos.map((catalogo) => (catalogo.id === payload.id ? payload : catalogo))
                };
            case DELETE_CATALOGO:
                
                return {
                    ...state,
                    catalogos : state.catalogos.filter((catalogo) => (catalogo.id !== payload))
                };
    }
}

export default TablaCatalogoReducer;