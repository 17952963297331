import { Formik, Form, Field } from "formik";
import { useContext, useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import TiendaContext from "../../context/tienda/TiendaContext";
import { REACT_APP_API } from "../../service/env";
import { desencriptarDatos } from "../../service/AES.js";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function ContrasenaCompra() {
  const { carrito, validaCuenta, addBoleta } = useContext(TiendaContext);
  const [open, setOpen] = useState(false);

  const enviarFormulario = (url, token, { setSubmitting }) => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;

    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "token_ws";
    hiddenField.value = token;

    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();

    setSubmitting(false); // Importante para indicar que el envío ha terminado.
  };

  var precioCarrito = 0;
  var cantProducts = 0;

  carrito !== null &&
    carrito.map((productos) => {
      precioCarrito += productos.precio * productos.cantidad;
      cantProducts += parseInt(productos.cantidad);
    });

  const queryParams = new URLSearchParams(window.location.search);
  const rut = queryParams.get("rut");

  const empresa = JSON.parse(desencriptarDatos("empresa"));
  const cuota = empresa.cuota;
  const webPay = empresa.webPay;
  const logo = empresa.imagen_logo;

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt-20">
        <img className="mx-auto h-20 w-auto" src={REACT_APP_API + "/" + logo} />
      </div>
      <h1 className="mt-6 text-center text-m font-bold tracking-tight text-gray-900">
        Debes ingresar tu contraseña para continuar con la compra.
      </h1>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            initialValues={{
              id_empresa: empresa.id,
              rut: rut,
              password: "",
              checked: false,
              numero_cuotas: 1,
            }}
            onSubmit={async (values) => {
              const res = await validaCuenta(values);
              if (res.res) {
                const data = {
                  id_beneficiario: res.persona.id,
                  carrito,
                  url: window.location.href.split("/datos-compra")[0],
                  webPay: values.webPay,
                  cuota: values.cuota,
                  numero_cuotas: values.numero_cuotas,
                  despacho: empresa.direcciones[0],
                };

                const resBoleta = await addBoleta(data);
                if (webPay === 1) {
                  enviarFormulario(resBoleta.url, resBoleta.token, {
                    setSubmitting: () => {},
                  });
                } else if (cuota === 1) {
                  if (resBoleta.estado) {
                    window.location.href =
                      data.url + "/valida?id=" + resBoleta.id;
                  } else {
                    toast.error(resBoleta.mensaje);
                  }
                }
              } else {
                toast.error(res.message);
              }
            }}
            validationSchema={Yup.object({
              password: Yup.string().required("Contraseña es requerida."),
              checked: Yup.boolean()
                .oneOf([true], "Debes aceptar los términos y condiciones.")
                .required(),
            })}
          >
            {({ errors, touched, values, setValues, isSubmitting }) => (
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="rut"
                    className="block text-sm font-medium text-gray-700"
                  >
                    RUT
                  </label>
                  <Field
                    name="rut"
                    className="disable cursor-default block w-full appearance-none rounded-md border px-3 py-2 shadow-sm sm:text-sm border-gray-300 bg-gray-200 text-gray-600 focus:outline-none"
                    value={rut}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contraseña
                  </label>
                  <div className="mt-1">
                    <Field
                      name="password"
                      type="password"
                      className={classNames(
                        errors.password == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.password
                          ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.password && touched.password && (
                      <div className="text-red-500 text-sm">
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-1">
                  <label className="text-sm font-medium text-gray-700 flex items-center">
                    <Field
                      type="checkbox"
                      name="checked"
                      className="accent-indigo-700"
                    />
                    <button
                      onClick={() => setOpen(!open)}
                      className="ml-2 flex justify-between"
                    >
                      Acepto los términos y condiciones{" "}
                      {open ? (
                        <ChevronUpIcon className="h-5 w-4 ml-2" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-4 ml-2" />
                      )}
                    </button>
                  </label>
                  {open && (
                    <div className="text-sm text-gray-500 mt-2">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                      <p className="mt-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                  )}
                  {errors.checked && touched.checked && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.checked}
                    </div>
                  )}
                </div>

                <div className="flex flex-col justify-center">
                  {webPay === 1 && (
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`mx-1 flex font-bold w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                        isSubmitting
                          ? "bg-indigo-300"
                          : "bg-indigo-600 hover:bg-indigo-700"
                      }`}
                      onClick={() => {
                        if (!isSubmitting) {
                          setValues({
                            ...values,
                            webPay: 1,
                            cuota: 0,
                          });
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.789 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Pagar con WebPay"
                      )}
                    </button>
                  )}
                  {cuota === 1 && (
                    <div className="flex flex-col ">
                      <div className="mt-1 mb-5">
                        <label
                          htmlFor="numero_cuotas"
                          className="block text-sm font-medium leading-6 text-gray-700"
                        >
                          Número de cuotas
                        </label>
                        <div className="mt-1">
                          <Field
                            name="numero_cuotas"
                            type="number"
                            min="0"
                            defaultValue="0"
                            required
                            max="3"
                            className={classNames(
                              errors.numero_cuotas === undefined
                                ? "ring-gray-300 focus:border-indigo-600 focus:ring-indigo-600"
                                : touched.numero_cuotas
                                ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                                : "ring-gray-300 focus:border-indigo-600 focus:ring-indigo-600",
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            )}
                          />
                          {errors.numero_cuotas && touched.numero_cuotas && (
                            <div className="text-red-500 text-sm">
                              {errors.numero_cuotas}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`mx-1 flex font-bold w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                            isSubmitting
                              ? "bg-indigo-300"
                              : "bg-indigo-600 hover:bg-indigo-700"
                          }`}
                          onClick={() => {
                            if (!isSubmitting) {
                              setValues({
                                ...values,
                                webPay: 0,
                                cuota: 1,
                                numero_cuotas:
                                  values.numero_cuotas === 0
                                    ? 1
                                    : values.numero_cuotas,
                              });
                            }
                          }}
                        >
                          {isSubmitting ? (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.789 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            "Pagar en cuotas"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default ContrasenaCompra;
