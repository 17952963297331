import { useContext, useEffect, useState } from "react";
import TablaPedidosContext from "../../../context/tablaPedidos/TablaPedidoContext";
import ResumenPedido from "./resumenPedido";
import EntregarPedido from "./EntregarPedido";
import { MagnifyingGlassPlusIcon } from "@heroicons/react/24/outline";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TablaPedidos() {
  const {
    pedidos,
    setOpenViewPedido,
    setOpenNumPedido,
    getPedidoVendedor,
    cargaEstado,
    getPedidoAdminEmpresa,
  } = useContext(TablaPedidosContext);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * 8;
  const indexOfFirstItem = indexOfLastItem - 8;
  const currentItems = pedidos?.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const resultsStart = indexOfFirstItem + 1;
  const resultsEnd = Math.min(indexOfLastItem, pedidos?.length);

  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "pedidos") {
      getPedidoVendedor();
    } else {
      getPedidoAdminEmpresa(window.location.pathname.split("/")[2]);
    }
  }, []);

  return (
    <>
      <EntregarPedido />
      <ResumenPedido />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="block min-w-full py-2 align-middle">
              <div className="ring-1 ring-black ring-opacity-5 overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-8 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Fecha Compra
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-8 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Nombre
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Rut
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Despacho
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Opciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentItems?.map((pedido, pedidoIdx) => (
                      <tr key={pedido.boleta_id} className="hover:bg-slate-50">
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-8 py-3.5 text-sm text-gray-500"
                          )}
                        >
                          {moment(pedido.fecha).format("DD/MM/YYYY")}
                          <div className="text-xs">
                            {moment(pedido.fecha).format("HH:mm:ss")}
                          </div>
                        </td>
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-3.5 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {pedido.beneficiario_nombres}{" "}
                          {pedido.beneficiario_apellido_paterno}{" "}
                          {pedido.beneficiario_apellido_materno}
                        </td>
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-4 py-3.5 text-sm text-gray-500"
                          )}
                        >
                          {pedido.rut_formateado}
                        </td>
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-4 py-3.5 text-sm text-gray-500 capitalize hidden sm:table-cell"
                          )}
                        >
                          {pedido.despacho}
                        </td>
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-4 py-3.5 text-sm text-gray-500"
                          )}
                        >
                          <button
                            type="button"
                            className="mr-1 inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 sm:w-auto"
                            onClick={() => setOpenViewPedido(true, pedido)}
                          >
                            <MagnifyingGlassPlusIcon className="w-5 h-5" />
                          </button>
                          {pedido.boleta_estado === 2 ||
                          pedido.boleta_estado === 3 ? (
                            <button
                              type="button"
                              className="ml-1 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm capitalize hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                              onClick={() => {
                                setOpenNumPedido(true, pedido);
                              }}
                            >
                              entregar
                            </button>
                          ) : (
                            cargaEstado(pedido.boleta_estado)
                          )}
                        </td>
                      </tr>
                    ))}
                    {pedidos.length === 0 && (
                      <tr
                        className="border-t border-gray-200 bg-white"
                        style={{ height: 80 }}
                      >
                        <td
                          colSpan={6}
                          className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 text-center"
                        >
                          No se encontraron resultados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pedidos.length > 0 && (
                  <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        <span className="font-medium">
                          {resultsStart} - {resultsEnd} Resultados de{" "}
                          {pedidos?.length}
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                      <button
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        Anterior
                      </button>
                      <button
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 bg-cyan-700 hover:bg-gray-50"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= pedidos?.length}
                      >
                        Siguiente
                      </button>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
