import { REACT_APP_API } from "../../service/env";
import { desencriptarDatos } from "../../service/AES.js";

export default function CompraRechazada() {
  const empresa = JSON.parse(desencriptarDatos("empresa"));

  return (
    <>
      <main className="relative">
        <div className="h-80 bg-gray-100 overflow-hidden lg:fixed lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12 flex justify-center items-center">
          <img
            className="object-contain h-full w-full md:w-1/2"
            src={REACT_APP_API + "/" + empresa.imagen_logo}
            alt="logo"
          />
        </div>
        <div className="h-screen">
          <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:grid  lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
            <div className="lg:col-start-2">
              <div>
                <h1 className="text-sm font-medium text-indigo-600">
                  Pago Rechazado
                </h1>
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  ¡Lo Sentimos!
                </p>
                <p className="mt-2 text-base text-gray-500">
                  Su compra ha sido rechaza debido a que presenta una deuda
                  pendiente.
                </p>
              </div>

              <div className="mt-16 border-t border-gray-200 py-6 text-right">
                <a
                  href={window.location.href.split("/pago-rechazado")[0]}
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Continuar Comprando
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
