export const SET_OPEN_SIDEBAR = "SET_OPEN_SIDEBAR"
export const SET_EMPRESA = "SET_EMPRESA"
export const SET_ESTADISTICA = "SET_ESTADISTICA"

export const SET_OPEN_ADDPRODUCTO = "SET_OPEN_ADDPRODUCTO"
export const SET_OPEN_UPDATEPRODUCTO = "SET_OPEN_UPDATEPRODUCTO"
export const SET_OPEN_DELETEPRODUCTO = "SET_OPEN_DELETEPRODUCTO"
export const SET_PRODUCTO = "SET_PRODUCTO"
export const UPDATE_PRODUCTO = "UPDATE_PRODUCTO"
export const DELETE_PRODUCTO = "DELETE_PRODUCTO"
export const ADD_PRODUCTO = "ADD_PRODUCTO"

export const SET_OPEN_ADDCATALOGO = "SET_OPEN_ADDCATALOGO"
export const SET_CATALOGO = "SET_CATALOGO"
export const SET_OPEN_UPDATECATALOGO = "SET_OPEN_UPDATECATALOGO"
export const SET_OPEN_DELETECATALOGO = "SET_OPEN_DELETECATALOGO"
export const UPDATE_CATALOGO = "UPDATE_CATALOGO"
export const DELETE_CATALOGO = "DELETE_CATALOGO"
export const SET_OPEN_ANEXACATALOGO = "SET_OPEN_ANEXACATALOGO"
export const SET_CAT_PROD = "SET_CAT_PROD"

export const SET_OPEN_PEDIDO_DETAILS = "SET_OPEN_VIEWPEDIDO"
export const SET_ENTREGAR_PEDIDO = "SET_ENTREGAR_PEDIDO"
export const SET_OPEN_NUM_PEDIDO = "SET_OPEN_NUM_PEDIDO"
export const SET_ID_PEDIDO = "SET_ID_PEDIDO"
export const SET_APROBAR = "SET_APROBAR"
export const SET_RECHAZAR = "SET_RECHAZAR"
export const SET_PEDIDO = "SET_PEDIDO"
export const SET_ACTUALIZAR_ESTADO = "SET_ACTUALIZAR_ESTADO"
export const VIEW_PEDIDOS = "VIEW_PEDIDO"
export const SET_CARRITO_INICIAL = "SET_CARRITO_INICIAL"

export const SET_OPEN_AGREGAR_BENEFICIARIO = "SET_OPEN_AGREGAR_BENEFICIARIO"
export const SET_BENEFICIARIO = "SET_BENEFICIARIO"
export const SET_OPEN_IMPORTAR_BENEFICIARIOS = "SET_OPEN_IMPORTAR_BENEFICIARIOS"
export const SET_OPEN_DETALLE_BENEFICIARIO = "SET_OPEN_DETALLE_BENEFICIARIO"
export const SET_OPEN_EDITA_BENEFICIARIO = "SET_OPEN_EDITA_BENEFICIARIO"
export const SET_OPEN_DELETE_BENEFICIARIO = "SET_OPEN_DELETE_BENEFICIARIO"

export const SET_CARRITO = "SET_CARRITO"
export const DELETE_PROD_CARRITO = "DELETE_PROD_CARRITO"
export const SET_PRODUCTO_DETALLE ="SET_PRODUCTO_DETALLE"
export const UPDATE_CANTIDAD = "UPDATE_CANTIDAD"
export const ADD_LOGO = "ADD_LOGO"
export const SET_BUSQUEDA = "SET_BUSQUEDA"
export const SET_CARGANDO = "SET_CARGANDO"