import { useContext, useEffect } from 'react';
import 'tw-elements';
import NavBarIndex from '../../components/tienda/navBarIndex';
import TiendaContext from '../../context/tienda/TiendaContext';
import { REACT_APP_API } from '../../service/env';
import { encriptarDatos } from '../../service/AES';

export function InicioComercio({ empresa }) {

  const { getCatalogoEmpresa, catalogos } = useContext(TiendaContext)


  useEffect(() => {
    encriptarDatos('empresa',JSON.stringify(empresa))
    getCatalogoEmpresa(empresa.id)
  }, [])

  return (
    <div className="bg-white">
      <NavBarIndex empresa={empresa}/>
      <main>
        <section aria-labelledby="category-heading" className="py-10 sm:pt-10 xl:mx-auto xl:max-w-7xl xl:px-8">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl py-10 sm:py-24 lg:max-w-none lg:py-32">
                <h2 className="text-4xl font-bold text-gray-900">Catálogos</h2>

                <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                {catalogos.map((callout) => (
                    <div key={callout.id}>
                      { callout.productos !== null && callout.productos.length > 0 && (
                        <div key={callout.id} className="group relative">
                          <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                            <img
                              src={REACT_APP_API + '/' + callout.productos[0].imagen}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <h3 className="mt-6 text-sm text-gray-500">
                            <a href={'/' + empresa.nombre + '/catalogo/' + callout.id}>
                              <span className="absolute inset-0" />
                              {callout.nombre}
                            </a>
                          </h3>
                          <p className="text-base font-semibold text-gray-900">{callout.descripcion}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
        </section>
      </main>

    </div>
  )
}
