import { useContext, useEffect, useState } from "react";
import TablaPedidosContext from "../../../context/tablaPedidos/TablaPedidoContext";
import ResumenPedido from "../../dashboardVendedor/pedidos/resumenPedido";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TablaPedidos() {
  const {
    pedidos,
    actualizarEstado,
    getPedidoAdminEmpresa,
    getEmpresaById,
    cargaEstado,
  } = useContext(TablaPedidosContext);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * 8;
  const indexOfFirstItem = indexOfLastItem - 8;
  const currentItems = pedidos?.slice(indexOfFirstItem, indexOfLastItem);
  const resultsStart = indexOfFirstItem + 1;
  const resultsEnd = Math.min(indexOfLastItem, pedidos?.length);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    async function fetchData() {
      const empresa = await getEmpresaById(localStorage.getItem("id_empresa"));
      getPedidoAdminEmpresa(empresa.nombre);
    }
    fetchData();
  }, []);

  return (
    <>
      <ResumenPedido />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="ring-1 ring-black ring-opacity-5 overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-8 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Fecha
                      </th>
                     
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Nombre
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        RUT
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Monto
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter hidden sm:table-cell"
                      >
                        Pago
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentItems?.map((pedido, pedidoIdx) => (
                      <tr key={pedido.id} className="hover:bg-slate-50">
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-8 py-3.5 text-sm text-gray-500"
                          )}
                        >
                          {moment(pedido.fecha).format("DD/MM/YYYY")}
                          <div className="text-xs">
                            {moment(pedido.fecha).format("HH:mm:ss")}
                          </div>
                        </td>
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {pedido.beneficiario_nombres} {" "}
                          {pedido.beneficiario_apellido_paterno}{" "}
                          {pedido.beneficiario_apellido_materno}
                        </td>
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm text-gray-500"
                          )}
                        >
                          {pedido.rut_formateado}
                        </td>
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-wrap px-4 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {pedido.total.toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          })}
                        </td>
                        {pedido.pago_con_cuotas === true ? (
                          <td
                            className={classNames(
                              pedidoIdx !== pedidos.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-wrap px-4 py-4 text-sm text-gray-500 hidden sm:table-cell"
                            )}
                          >
                            {pedido.cuota}
                          </td>
                        ) : (
                          <td
                            className={classNames(
                              pedidoIdx !== pedidos.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-wrap px-4 py-4 text-sm text-gray-500 hidden sm:table-cell"
                            )}
                          >
                            WEBPAY
                          </td>
                        )}
                        <td
                          className={classNames(
                            pedidoIdx !== pedidos.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                          )}
                        >
                          {pedido.boleta_estado === 1 ? (
                            <div className="ml-5 grid grid-cols-2 w-40 place-content-center ">
                              <button
                                type="button"
                                className="mr-1 inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 py-2 text-sm text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2 sm:w-auto font-bold"
                                onClick={() => {
                                  pedido.boleta_estado = 2;
                                  actualizarEstado(pedido);
                                }}
                              >
                                APROBAR
                              </button>
                              <button
                                type="button"
                                className="mr-1 px-10 inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 py-2 text-sm text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-offset-2 sm:w-auto font-bold"
                                onClick={() => {
                                  pedido.boleta_estado = 7;
                                  actualizarEstado(pedido);
                                }}
                              >
                                RECHAZAR
                              </button>
                            </div>
                          ) : (
                            cargaEstado(pedido.boleta_estado)
                          )}
                        </td>
                      </tr>
                    ))}
                    {pedidos.length === 0 && (
                      <tr
                        className="border-t border-gray-200 bg-white"
                        style={{ height: 80 }}
                      >
                        <td
                          colSpan={6}
                          className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 text-center"
                        >
                          No se encontraron resultados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pedidos.length > 0 && (
                  <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        <span className="font-medium">
                          {resultsStart} - {resultsEnd} Resultado de{" "}
                          {pedidos?.length}
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                      <button
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                      >
                        Anterior
                      </button>
                      <button
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={nextPage}
                        disabled={indexOfLastItem >= pedidos?.length}
                      >
                        Siguiente
                      </button>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
