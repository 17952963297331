import { useContext, useState, Fragment, useEffect } from "react";
import TablaProductosContext from "../../../context/tablaProducto/TablaProductoContext";
import TablaCatalogosContext from "../../../context/tablaCatalogo/TablaCatalogoContext";
import { Formik, Form, Field, FieldArray } from "formik";
import { Listbox, Transition, Switch } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  PlusIcon as PlusIconMini,
} from "@heroicons/react/20/solid";
import * as Yup from "yup";
import { REACT_APP_API } from "../../../service/env";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FormEditaProducto() {
  const { setOpenUpdateProducto, updateProducto, editaProducto } = useContext(
    TablaProductosContext
  );
  const { catalogos, getCatalogo } = useContext(TablaCatalogosContext);

  const estadoTalla = editaProducto
    ? editaProducto.tiene_talla === 0 || editaProducto.tiene_talla === null
      ? false
      : true
    : false;
  const estadoMedida = editaProducto
    ? editaProducto.tiene_medida === 0 || editaProducto.tiene_medida === null
      ? false
      : true
    : false;
  const estadoLogo = editaProducto
    ? editaProducto.logo === 0 || editaProducto.logo === null
      ? false
      : true
    : false;

  const [activaTalla, setActivaTalla] = useState(estadoTalla);
  const [activaMedida, setActivaMedida] = useState(estadoMedida);
  const [logo, setLogo] = useState(estadoLogo);
  const [selected, setSelected] = useState(null);
  const newcolors = [];
  const newtallas = [];

  if (editaProducto.colores?.includes(",")) {
    editaProducto.colores?.split(",").map((color) => {
      newcolors.push(color);
    });
  } else if (
    !editaProducto.colores?.includes(",") &&
    editaProducto.colores !== ""
  ) {
    newcolors.push(editaProducto.colores);
  }

  if (editaProducto.tallas?.includes(",")) {
    editaProducto.tallas?.split(",").map((talla) => {
      newtallas.push(talla);
    });
  } else if (
    !editaProducto.tallas?.includes(",") &&
    editaProducto.tallas !== null
  ) {
    newtallas.push(editaProducto.tallas);
  }

  useEffect(() => {
    getCatalogo();
    setSelected(catalogos.find((catalogo) => catalogo.id === editaProducto.id_catalogo));
  }, []);

  return (
    <Formik
      initialValues={{
        ...editaProducto,
        colores: newcolors,
        tallas: newtallas,
      }}
      onSubmit={async (values) => {
        if (values.tiene_medida === null) values.tiene_medida = 0;
        if (values.tiene_talla === null) values.tiene_talla = 0;

        const res = await updateProducto(values);

        if (res === true) {
          setOpenUpdateProducto(false);
        } else {
          setOpenUpdateProducto(false);
        }
      }}
      validationSchema={Yup.object({
        nombre: Yup.string().required("Nombre es requerido."),
        descripcion: Yup.string().required("Descripcion es requerida."),
        colores: Yup.array()
          .min(1, "Debe elegir almenos un color")
          .required("Color es requerido."),
        imagen: Yup.mixed()
          .required("Imagen es requerida.")
          .test("fileSize", "El archivo es muy grande", (value) => {
            return value && value.size <= 1000000;
          })
          .test("fileType", "El archivo no es una imagen", (value) => {
            return value && value.type.includes("image/");
          }),
        precio: Yup.number()
          .min(1, "El precio no puede ser 0")
          .required("Precio es requerido."),
        imagen: Yup.mixed().required("Imagen es requerida."),
      })}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="sm:flex sm:items-start space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Editar Producto
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Rellene los campos que sea editar del producto. Recuerde que
                  talla y medida no son requeridos si lo desea.
                </p>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Nombre
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="nombre"
                      className={classNames(
                        errors.nombre == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.nombre && touched.nombre && (
                      <div className="text-red-500 text-sm">
                        {errors.nombre}
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                          Catálogo asociado
                        </Listbox.Label>
                        <div className="relative mt-1 col-span-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                            <span className="block truncate">
                              {`${selected?.nombre} (${selected?.empresa})`}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {catalogos.map((catalogos) => (
                                <Listbox.Option
                                  key={catalogos.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-indigo-600"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-8 pr-4"
                                    )
                                  }
                                  onClick={() => {
                                    values.id_catalogo = catalogos.id;
                                  }}
                                  value={catalogos}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {`${catalogos.nombre} (${catalogos.empresa})`}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  {errors.empresa && (
                    <div className="text-red-500 text-sm">{errors.empresa}</div>
                  )}
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="descripcion"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Descripción
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      as="textarea"
                      name="descripcion"
                      rows={4}
                      className={classNames(
                        errors.descripcion == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.descripcion && (
                      <div className="text-red-500 text-sm">
                        {errors.descripcion}
                      </div>
                    )}
                  </div>
                </div>

                <FieldArray name="colores">
                  {({ remove, push }) => (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Colores
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0 flex row">
                        <Field
                          type="text"
                          name="color"
                          className={classNames(
                            errors.colores == undefined
                              ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                              : "border-red-300 focus:border-red-500 focus:ring-red-500",
                            "block w-full max-w-lg rounded-md shadow-sm sm:text-sm"
                          )}
                        />
                        <button
                          type="button"
                          className="mx-1 inline-flex items-center rounded-full border border-transparent bg-green-600 p-1.5 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          onClick={() => {
                            if (values.color !== "") {
                              push(values.color.toLocaleUpperCase());
                              setFieldValue("color", "");
                            }
                          }}
                        >
                          <PlusIconMini
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                        {errors.colores &&
                          touched.colores &&
                          values.colores.length === 0 && (
                            <div>
                              <p className="text-red-500 text-sm">
                                {errors.colores}
                              </p>
                            </div>
                          )}
                      </div>
                      {values.colores.length > 0 && (
                        <div className="mt-1 pt-2 sm:col-span-4 flex justify-start row overflow-scroll">
                          {values.colores?.map((element, index) => (
                            <span className="mx-1 inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700">
                              {element}
                              <button
                                key={index}
                                type="button"
                                className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <span className="sr-only">
                                  Remove large option
                                </span>
                                <svg
                                  className="h-2 w-2"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 8 8"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeWidth="1.5"
                                    d="M1 1l6 6m0-6L1 7"
                                  />
                                </svg>
                              </button>
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </FieldArray>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    ¿Tiene talla?
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Switch
                      checked={activaTalla}
                      onChange={setActivaTalla}
                      onClick={() => {
                        values.tallas = [];
                        setFieldValue("tiene_talla", activaTalla ? 0 : 1);
                      }}
                      className={classNames(
                        activaTalla ? "bg-indigo-600" : "bg-gray-200",
                        "mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          activaTalla ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                </div>
                {activaTalla && (
                  <FieldArray name="tallas">
                    {({ remove, push }) => (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Tallas
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex row">
                          <Field
                            type="text"
                            name="talla"
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          />
                          <button
                            type="button"
                            className="ml-1 inline-flex items-center rounded-full border border-transparent bg-green-600 p-1.5 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            onClick={() => {
                              if (values.talla !== "") {
                                push(values.talla.toLocaleUpperCase());
                                setFieldValue("talla", "");
                              }
                            }}
                          >
                            <PlusIconMini
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                        {values.tallas.length > 0 && (
                          <div className="mt-1 pt-2 sm:col-span-4 flex justify-start row">
                            {values.tallas?.map((element, index) => (
                              <span className="mx-1 inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700">
                                {element}
                                <button
                                  key={index}
                                  type="button"
                                  className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <span className="sr-only">
                                    Remove large option
                                  </span>
                                  <svg
                                    className="h-2 w-2"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 8 8"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeWidth="1.5"
                                      d="M1 1l6 6m0-6L1 7"
                                    />
                                  </svg>
                                </button>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </FieldArray>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    ¿Tiene medidas?
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Switch
                      checked={activaMedida}
                      onChange={setActivaMedida}
                      onClick={() => {
                        setFieldValue("tiene_medida", activaMedida ? 0 : 1);
                      }}
                      className={classNames(
                        activaMedida ? "bg-indigo-600" : "bg-gray-200",
                        "mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          activaMedida ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                </div>
                {activaMedida && (
                  <>
                    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Alto
                      </label>
                      <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                        <Field
                          type="text"
                          name="alto"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          aria-describedby="alto"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span className="text-gray-500 sm:text-sm" id="alto">
                            CM
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Ancho
                      </label>
                      <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                        <Field
                          type="text"
                          name="ancho"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          aria-describedby="ancho"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span className="text-gray-500 sm:text-sm" id="ancho">
                            CM
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Largo
                      </label>
                      <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                        <Field
                          type="text"
                          name="largo"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          aria-describedby="largo"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span className="text-gray-500 sm:text-sm" id="largo">
                            CM
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    ¿Logo oblligatorio?
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Switch
                      checked={logo}
                      onChange={setLogo}
                      onClick={() => {
                        setFieldValue("logo", logo ? 0 : 1);
                      }}
                      className={classNames(
                        logo ? "bg-indigo-600" : "bg-gray-200",
                        "mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          logo ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Precio
                  </label>
                  <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <Field
                      type="text"
                      name="precio"
                      className={classNames(
                        errors.precio == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "ml-4 block w-full max-w-lg rounded-md shadow-sm sm:text-sm"
                      )}
                    />
                  </div>
                </div>
                {errors.precio && (
                  <div className="text-red-500 text-sm">{errors.precio}</div>
                )}
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Imagen
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div
                      className={classNames(
                        errors.imagen == undefined
                          ? "border-gray-300"
                          : "border-red-300",
                        "flex max-w-lg justify-center rounded-md border-2 border-dashed  px-6 pt-5 pb-6"
                      )}
                    >
                      {values.imagen === null && (
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="imagen"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="imagen"
                                name="imagen"
                                onChange={(e) =>
                                  setFieldValue("imagen", e.target.files[0])
                                }
                                type="file"
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      )}
                      {values.imagen !== null && (
                        <img
                          src={
                            typeof values.imagen == "string"
                              ? REACT_APP_API + "/" + values.imagen
                              : URL.createObjectURL(values.imagen)
                          }
                        />
                      )}
                    </div>
                    {errors.imagen && (
                      <div className="text-red-500 text-sm">
                        {errors.imagen}
                      </div>
                    )}
                    {values.imagen !== null && (
                      <button
                        type="button"
                        className="mt-5 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                        onClick={() => {
                          setFieldValue("imagen", null);
                        }}
                      >
                        Cambiar imagen
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-4 pt-5 sm:flex sm:flex-row-reverse">
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => setOpenUpdateProducto(false)}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Guardar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
