import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import TablaBeneficiariosContext from "../../../context/tablaBeneficiarios/TablaBeneficiariosContext";
import moment from "moment";

export default function ModalDetalleBeneficiario() {
  const {
    openDetalleBeneficiario,
    setOpenDetalleBeneficiario,
    detalleBeneficiario,
  } = useContext(TablaBeneficiariosContext);
  return (
    <Transition.Root show={openDetalleBeneficiario} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setOpenDetalleBeneficiario}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center p-4 text-center sm:items-center sm:p-0 sm:w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 opacity-100 translate-y-0 sm:scale-100">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                    <DocumentMagnifyingGlassIcon
                      className="h-6 w-6 text-yellow-600"
                      aria-hidden="true"
                    />
                  </div>
                  {detalleBeneficiario.nombres !== undefined && (
                    <div className="mt-3 text-center sm:mt-5">
                      <div className="mt-2 overflow-hidden bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Detalle {detalleBeneficiario.nombres.split(" ")[0]}{" "}
                            {detalleBeneficiario.nombres.split(" ")[2]}
                          </h3>
                        </div>
                        <div className="border-t border-gray-200">
                          <dl>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                RUT
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {detalleBeneficiario.rut}
                              </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Nombre
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {detalleBeneficiario.nombres}{" "}
                                {detalleBeneficiario.apellido_paterno}{" "}
                                {detalleBeneficiario.apellido_materno}
                              </dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Fecha Ingreso
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {detalleBeneficiario.fecha_ingreso === undefined
                                  ? "No hay datos"
                                  : moment(
                                      detalleBeneficiario.fecha_ingreso
                                    ).format("DD/MM/YYYY")}
                              </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Fecha Nacimiento
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {detalleBeneficiario.fecha_nacimiento === undefined || detalleBeneficiario.fecha_nacimiento === null
                                  ? "No hay datos"
                                  : moment(
                                      detalleBeneficiario.fecha_nacimiento
                                    ).format("DD/MM/YYYY")}
                              </dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Dirección
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {detalleBeneficiario.direccion === undefined || detalleBeneficiario.direccion === null
                                  ? "No hay datos"
                                  : detalleBeneficiario.direccion}
                              </dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Función
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {detalleBeneficiario.funcion === undefined || detalleBeneficiario.funcion === null
                                  ? "No hay datos"
                                  : detalleBeneficiario.funcion}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => setOpenDetalleBeneficiario(false, {})}
                  >
                    Cerrar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
