import { SET_EMPRESA, SET_OPEN_SIDEBAR, SET_ESTADISTICA } from "../types";

const SideBarReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_OPEN_SIDEBAR:
      return {
        ...state,
        estado: payload,
      };
    case SET_EMPRESA:
      return {
        ...state,
        empresas: payload,
      };
    case SET_ESTADISTICA:
      return {
        ...state,
        estadisticas: payload,
      };
  }
};

export default SideBarReducer;
