import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { recuperarPass } from "../api/recuperarpass.api";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { REACT_APP_API } from "../service/env";
import { desencriptarDatos } from "../service/AES";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function RecuperarContrasena() {
  const empresa = JSON.parse(desencriptarDatos("empresa"));

  let currentLink = "";
  const location = window.location.pathname.split("/");
  location.pop();
  location.map((link) => {
    currentLink += link + "/";
  });

  useEffect(() => {
    document.documentElement.className = "h-full bg-gray-50";
    document.body.className = "h-full";
    document.getElementById("root").className =
      "flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8";
  }, []);

  return (
    <>
      <div className="flex absolute top-0 left-0 p-5">
        <a
          href={"/" + empresa.nombre}
          className="text-sm"
          style={{
            color: empresa.color_primario ? empresa.color_primario : "#000",
          }}
        >
          <span aria-hidden="true" className="p-2">
            {" "}
            &larr;
          </span>
          Inicio
        </a>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-20 w-auto flex justify-center"
          alt="logo"
          src={REACT_APP_API + "/" + empresa.imagen_logo}
        />
        <p className="mt-8 font-semibold text-center ">Recuperar Contraseña</p>
        <p className="text-sm font-thin text-center text-gray-500">
          Al ingresar su rut se le enviará un correo electrónico con los pasos a
          seguir
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            initialValues={{
              rut: "",
            }}
            onSubmit={async (values) => {
              const res = await recuperarPass(values);

              if (res.data.state === "success") {
                window.location.href = "/";
              } else {
                toast.error("Rut inválido");
              }
            }}
            validationSchema={Yup.object({
              rut: Yup.string()
                .required("Ingrese su rut sin puntos y con guión.")
                .matches(/^[0-9]+-[0-9kK]{1}$/, "Rut inválido.")
                .length(10, "Rut inválido."),
            })}
          >
            {({ errors }) => (
              <Form className="space-y-6" action="#" method="GET">
                <div>
                  <label
                    htmlFor="rut"
                    className="block text-sm font-medium text-gray-700"
                  >
                    RUT
                  </label>
                  <div className="mt-1">
                    <Field
                      name="rut"
                      className={classNames(
                        errors.rut == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : "border-red-300 focus:border-red-500 focus:ring-red-500",
                        "block w-full appearance-none focus:outline-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                      )}
                      placeholder="XXXXXXXX-X"
                    />
                    {errors.rut && (
                      <div className="text-red-500 text-sm">{errors.rut}</div>
                    )}
                  </div>
                </div>
                <div className="mt-1 flex space-x-5 text-sm font-medium text-gray-600">
                  <Link
                    to={currentLink + "recuperar-contrasena"}
                    className="hover:underline decoration-1"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                  <Link
                    to={currentLink + "ingresar"}
                    className="hover:underline decoration-1"
                  >
                    Ingresar cuenta
                  </Link>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Aceptar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
