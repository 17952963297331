import axios from "axios";

export const addBeneficiarioApi = async (beneficiario) => {
    return await axios.post('/beneficiario/add', beneficiario)
}

export const deleteBeneficiarioApi = async (rut) => {
    return await axios.delete(`/beneficiario/delete/${rut}`)
}

export const getBeneficiarioApi = async (id_empresa) => {
    return await axios.get(`/beneficiario/${id_empresa}`)
}

export const getBeneficiarioRutApi = async (rut) => {
    return await axios.get(`/beneficiario/rut/${rut}`)
}

export const getAllBeneficiariosApi = async () => {
    return await axios.get('/beneficiario')
}

export const getBeneficiariosEmpresaApi = async (id_empresa) => {
    return await axios.get(`/beneficiario/empresa/${id_empresa}`)
}


export const addBeneficiarioExcelApi = async (beneficiario) => {
    
    const form = new FormData();

    for (let key in beneficiario){
        form.append(key, beneficiario[key]);
    }

    return await axios.post('/beneficiario/add/masivo', form, {
        headers : {
            "Content-Type" : "multipart/form-data"
        }
    })
    
}

export const editaBeneficiarioApi = async (beneficiario,id) => {
    return await axios.put(`/beneficiario/edit/${id}`, beneficiario);
}