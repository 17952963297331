import { useReducer } from "react";
import SideBarReducer from "./SideBarReducer";
import SideBarContext from "./SideBarContext";
import { SET_EMPRESA, SET_ESTADISTICA } from "../types";
import { getEmpresaApi } from "../../api/api";
import { getEstadisticas } from "../../api/estadistica.api";

export const SideBarState = (props) => {
  const initialState = {
    empresas: [],
    estadisticas: [],
  };

  const [sidebarOpen, dispatch] = useReducer(SideBarReducer, initialState);

  const getEmpresa = async () => {
    const empresa = await getEmpresaApi();
    if (empresa.data.message === "Empresas traidos exitosamente.") {
      dispatch({
        type: SET_EMPRESA,
        payload: empresa.data.data,
      });
    } else {
    }
  };

  const getEstadisticasEmpresas = async () => {
    const response = await getEstadisticas();
    if (response.data.message === "Monto total por mes.") {
      dispatch({
        type: SET_ESTADISTICA,
        payload: response.data.data.montos,
      });
    } else {
    }
  };

  return (
    <SideBarContext.Provider
      value={{
        empresas: sidebarOpen.empresas,
        estadisticas: sidebarOpen.estadisticas,
        getEmpresa,
        getEstadisticasEmpresas
      }}
    >
      {props.children}
    </SideBarContext.Provider>
  );
};
