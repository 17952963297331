import TablaCatalogosContext from "../../../context/tablaCatalogo/TablaCatalogoContext";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Fragment, useState, useContext } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import SideBarContext from "../../../context/sideBar/SideBarContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FormEditaCatalogo() {
  const { setOpenAddCatalogo, addCatalogo } = useContext(TablaCatalogosContext);
  const { empresas } = useContext(SideBarContext);

  const [selected, setSelected] = useState(empresas[0]);
  return (
    <Formik
      initialValues={{
        nombre: "",
        descripcion: "",
        id_empresa: 1,
        empresa: ""
      }}
      onSubmit={async (values) => {
        values.empresa = selected.nombre;
        const res = await addCatalogo(values);
        if (res === true) {
          setOpenAddCatalogo(false);
        } else {
          setOpenAddCatalogo(false);
        }
      }}
      validationSchema={Yup.object({
        nombre: Yup.string().required("Nombre es requerido."),
        descripcion: Yup.string().required("Descripcion es requerida."),
        id_empresa: Yup.number().required("Empresa es requerida."),
      })}
    >
      {({ errors, values, touched }) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="sm:flex sm:items-center space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Añadir Catálogo
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Rellene los campos para agrega un catálogo.
                </p>
              </div>

              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Nombre
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="nombre"
                      className={classNames(
                        errors.nombre == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.nombre ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm"
                      )}
                    />
                    {errors.nombre && touched.nombre && (
                      <div className="text-red-500 text-sm">
                        {errors.nombre}
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="descripcion"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Descripción
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      as="textarea"
                      name="descripcion"
                      rows={4}
                      className={classNames(
                        errors.descripcion == undefined
                          ? "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          : touched.descripcion ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                        "block w-full max-w-lg rounded-md shadow-sm sm:text-sm"
                      )}
                    />
                    {errors.descripcion && touched.descripcion && (
                      <div className="text-red-500 text-sm">
                        {errors.descripcion}
                      </div>
                    )}
                    <p className="mt-2 text-sm text-gray-500"></p>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                          Empresa
                        </Listbox.Label>
                        <div className="relative mt-1 col-span-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                            <span className="block truncate">
                              {selected.nombre}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-20 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-scroll">
                              {empresas.map((empresa) => (
                                <Listbox.Option
                                  key={empresa.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-indigo-600"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-8 pr-4"
                                    )
                                  }
                                  onClick={() => {
                                    values.id_empresa = empresa.id;
                                  }}
                                  value={empresa}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {empresa.nombre}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  {errors.empresa && (
                    <div className="text-red-500 text-sm">{errors.empresa}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-4 pt-5 sm:flex sm:flex-row-reverse">
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => setOpenAddCatalogo(false)}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                onClick={() => setOpenAddCatalogo(true)}
              >
                Guardar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
