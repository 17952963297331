import { useReducer } from "react";
import TablaCatalogosReducer from "./TablaCatalogoReducer";
import TablaCatalogosContext from "./TablaCatalogoContext";
import {
  SET_CATALOGO,
  SET_CAT_PROD,
  SET_OPEN_ADDCATALOGO,
  SET_OPEN_UPDATECATALOGO,
  SET_OPEN_DELETECATALOGO,
  DELETE_CATALOGO,
  UPDATE_CATALOGO,
  SET_OPEN_ANEXACATALOGO,
} from "../types";
import {
  addCatalogoApi,
  anexaCatalogoApi,
  deleteCatalogoApi,
  editCatalogoApi,
  getCatalogoApi,
  getCatProdApi,
} from "../../api/catalogo.api";

export const TablaCatalogosState = (props) => {
  const initialCatalogo = {
    estadoAddCatalogo: false,
    estadoUpdateCatalogo: false,
    estadoDeleteCatalogo: false,
    estadoAnexaCatalogo: false,
    editaCatalogo: {},
    anexaCatalogo: {},
    eliminaCatalogo: {},
    catProd: [],
    catalogos: [],
  };

  const [tablaCatalogos, dispatch] = useReducer(
    TablaCatalogosReducer,
    initialCatalogo
  );

  const setOpenAddCatalogo = (bool) => {
    dispatch({
      type: SET_OPEN_ADDCATALOGO,
      payload: bool,
    });
  };

  const setOpenAnexaCatalogo = (bool, id) => {
    dispatch({
      type: SET_OPEN_ANEXACATALOGO,
      payload: { bool, id },
    });
  };

  const setOpenUpdateCatalogo = (bool, id) => {
    dispatch({
      type: SET_OPEN_UPDATECATALOGO,
      payload: { bool, id },
    });
  };
  const setOpenDeleteCatalogo = (bool, id) => {
    dispatch({
      type: SET_OPEN_DELETECATALOGO,
      payload: { bool, id },
    });
  };

  const anexaCatalogoProductos = async (form) => {
    const query = await anexaCatalogoApi(form);

    await getCatalogo();
  };

  const getCatProd = async (id) => {
    const query = await getCatProdApi(id);

    dispatch({
      type: SET_CAT_PROD,
      payload: query.data.catalogos,
    });
  };

  const getCatalogo = async () => {
    const query = await getCatalogoApi();
    if (query.data.message === "Catalogos traidos exitosamente.") {
      dispatch({
        type: SET_CATALOGO,
        payload: query.data.data,
      });

      return true;
    } else {
        return false;
    }
  };

  const addCatalogo = async (catalogo) => {
    const query = await addCatalogoApi(catalogo);
    if (query.data.message === "Catalogo agregado exitosamente.") {
      catalogo.id = query.data.data.id;
      dispatch({
        type: SET_CATALOGO,
        payload: [...tablaCatalogos.catalogos, catalogo],
      });
      return true;
    } else {
      return false;
    }
  };

  const updateCatalogo = async (catalogo) => {
    const query = await editCatalogoApi(catalogo);
    if (query.data.message == "Catalogo editado exitosamente.") {
      dispatch({
        type: SET_CATALOGO,
        payload: tablaCatalogos.catalogos.map((cat) => {
          if (cat.id == catalogo.id) {
            return catalogo;
          } else {
            return cat;
          }
        }),
      });
      return true;
    } else {
      return false;
    }
  };

  const deleteCatalogo = async (id) => {
    const query = await deleteCatalogoApi(id);

    if (query.data.message === "Catalogo eliminado exitosamente.") {
      dispatch({
        type: SET_CATALOGO,
        payload: tablaCatalogos.catalogos.filter((cat) => cat.id != id),
      });
      return true;
    } else {
      return false;
    }
  };

  return (
    <TablaCatalogosContext.Provider
      value={{
        openAddCatalogo: tablaCatalogos.estadoAddCatalogo,
        openUpdateCatalogo: tablaCatalogos.estadoUpdateCatalogo,
        openDeleteCatalogo: tablaCatalogos.estadoDeleteCatalogo,
        openAnexaCatalogo: tablaCatalogos.estadoAnexaCatalogo,
        catalogos: tablaCatalogos.catalogos,
        editaCatalogo: tablaCatalogos.editaCatalogo,
        eliminaCatalogo: tablaCatalogos.eliminaCatalogo,
        anexaCatalogo: tablaCatalogos.anexaCatalogo,
        catProd: tablaCatalogos.catProd,
        setOpenAddCatalogo,
        setOpenUpdateCatalogo,
        setOpenDeleteCatalogo,
        setOpenAnexaCatalogo,
        anexaCatalogoProductos,
        getCatProd,
        getCatalogo,
        addCatalogo,
        updateCatalogo,
        deleteCatalogo,
      }}
    >
      {props.children}
    </TablaCatalogosContext.Provider>
  );
};
